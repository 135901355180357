export const ROOT = '/';
export const HOME = '/home';
export const PREVIEW = '/PREVIEW';
export const AUTH = {
    BASE: '/user',
    LOGIN: '/user/login',
    REGISTER: '/user/register',
    TOKEN: '/user/branch/activation',
    FORGOT_PASSWORD: '/user/forgot-password',
    RESET_PASSWORD: '/user/reset-password',
};

export const CATEGORIES = {
    LIST: '/categories',
    SHOW: '/categories/:id'
};

export const PRODUCTS = {
    BASE: '/products',
    SELF: {
        LIST: '/products',
        DETAILS: '/products/:id/details',
    },
    CATEGORIES: {
        SELF: '/products/categories',
        LIST: '/products/categories/list',
        CREATE: '/products/categories/create',
        UPDATE: '/products/categories/update/:id',
    }
};

export const SHOP = {
    SELF: '/shop',
    CART: '/shop/cart',
    WISHLIST: '/shop/wishlist',
    CHECKOUT: '/shop/checkout',
    PAYMENT: {
        SUCCESS: '/shop/payment-success',
    }
};

export const ACCOUNT = {
    SELF: '/account',
    ADDRESSES: {
        SELF: '/account/addresses',
        // GET_ALL: '/account/addresses/list',
        // GET_ONE: '/account/addresses/:id',
    },
    ORDERS: {
        SELF: '/account/orders',
        GET_ALL: '/account/orders/list',
        GET_ONE: '/account/orders/:id',
    },
    PROFILE: {
        SELF: '/account/profile',
        DETAILS: '/account/profile/details',
        EDIT: '/account/profile/details',
    },
};

export const OTHERS = {
    AUTH: '/auth',
    ABOUT: '/about',
    CONTACT: '/contact-us',
    NOT_FOUND: '/not-found',
};

export const joinUrlWithParams = (to, params) => {
    let url = to;
    params.forEach(param => {
        url = url.replace(`:${param.param}`, `${encodeURIComponent(param.value)}`);
    });

    return url;
};

export const joinUrlWithParamsId = (to, id) => {
    return joinUrlWithParams(to, [{ param: 'id', value: id }]);
};
