import Cart from "../models/Cart";
import storage from 'redux-persist/lib/storage';
import {
    SET_CART_TAX,
    SET_VOUCHER,
    SET_CART_ITEMS,
    SET_PAYMENT_METHOD,
} from "../actions/action-types";
import StorageHelper from "../helpers/StorageHelper";
import paymentMethods from "../data/paymentMethods";

const initialState = new Cart([]);

function cartReducer( state = initialState, action ) {
    switch ( action.type ) {
        case SET_CART_ITEMS:
            const savedPaymentType = StorageHelper.getItem(StorageHelper.PAYMENT_METHOD);
            let paymentMethod = paymentMethods.find(p => p.type === savedPaymentType);
            if (!paymentMethod) {
                paymentMethod = paymentMethods[1];
            }
            return new Cart(action.payload, state.voucher, paymentMethod, state.tax);

        case SET_PAYMENT_METHOD:
            return new Cart(state.cartItems, state.voucher, action.payload, state.tax);

        case SET_CART_TAX:
            return new Cart(state.cartItems, state.voucher, state.paymentMethod, action.payload);

        case SET_VOUCHER:
            return new Cart(state.cartItems, action.payload, state.paymentMethod, state.tax);

        default:
            return state;
    }
}

export default cartReducer;