import { combineReducers } from 'redux';

// Import custom components
import productReducer from './products';
import singleProduct from './singleProductReducer';
import cartReducer from './cart';
import compareReducer from './compare';
import filterReducer from './filter';
import modalReducer from './modal';
import categoriesReducer from './categoriesReducer';
import searchFilterReducer from './searchFilterReducer';
import productsReducer from './productsReducer';
import colorsReducer from './colorsReducer';
import requestGlobalLoader from './requestGlobalReducer';
import authUser from './authReducer';
import settings from './settingsReducer';
import billingAddresses from './billingAddressesReducer';
import orders from './ordersReducer';
import wishlistReducer from './wishlistReducer';
import geoAddress from './geoAddressReducer';

const rootReducer = combineReducers( {
    data: productReducer,
    cart: cartReducer,
    wishlist: wishlistReducer,
    compare: compareReducer,
    filters: filterReducer,
    modal: modalReducer,
    categories: categoriesReducer,
    searchFilter: searchFilterReducer,
    products: productsReducer,
    colors: colorsReducer,
    requestGlobalLoader,
    authUser,
    settings,
    billingAddresses,
    orders,
    singleProduct,
    geoAddress,
});

export default rootReducer;
