import React from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import SweetAlert from "react-bootstrap-sweetalert";
import {injectIntl} from "react-intl";
import IntlMessages from "../../helpers/IntlMessages";

const ConfirmBox = ({show, title, message, leftButtonText, leftButtonOnClick, rightButtonText, rightButtonOnClick, intl}) => {

    const _title = (title === undefined || title === null) ? intl.formatMessage({id: 'general.confirm'}) : title;

    return (
        <SweetAlert
            showCancel
            showConfirm
            show={show}
            title={_title}
            customButtons={(
                <>
                    <Button
                        color="primary"
                        onClick={leftButtonOnClick}
                        className="text-white bg-danger font-weight-bold mr-3"
                    >
                        {leftButtonText || <IntlMessages id="general.noWant" />}
                    </Button>
                    <Button
                        color="primary"
                        onClick={rightButtonOnClick}
                        className="text-white bg-primary font-weight-bold"
                    >
                        {rightButtonText || (<IntlMessages id="general.yesWant" />)}
                    </Button>
                </>
            )}
            onConfirm={rightButtonOnClick}
        >
            {message}
        </SweetAlert>
    );
};

ConfirmBox.propTypes = {
    show: PropTypes.bool.isRequired,
    message: PropTypes.any.isRequired,
    title: PropTypes.string,
    leftButtonText: PropTypes.string,
    leftButtonOnClick: PropTypes.func,
    rightButtonOnClick: PropTypes.func,
};

ConfirmBox.defaultProps = {
};

export default injectIntl(ConfirmBox);
