import {
  CART,
  PROFILE,
  PAYMENT,
  PRODUCTS,
  HOMEPAGE,
  PROVINCES,
  REACTIONS,
  SETTINGS,
  MARKET_PLACE,
  joinBaseUrlWithParams,
  joinBaseUrlWithParamsId,
} from '../urls/backendUrl';
import store from '../store';
import {getOrCreateVisitorId, makeRequest} from '../helpers/funcHelper';

export const getOneProduct = async (id) => {
  const data = {};
  const visitor = getOrCreateVisitorId();
  const authUser = store.getState().authUser.data;

  if (authUser) {
    data.userId = authUser.id;
  } else data.visitor = visitor;

  data.countryIso = "CAN";

  return await makeRequest('get', joinBaseUrlWithParamsId(PRODUCTS.GET_ONE, id), data);
};

export const verifyCoupon = async (code) => {
  return await makeRequest('get', joinBaseUrlWithParams(MARKET_PLACE.COUPON.CHECK, [{param: 'code', value: code}]))
};

export const getCountries = async () => {
  return makeRequest('get', PROVINCES.COUNTRIES.GET_ALL);
};

export const getReviews = (id) => {
  return makeRequest('get', joinBaseUrlWithParamsId(REACTIONS.REVIEWS.GET_ALL, id));
};

export const addReview = (data) => {
  return makeRequest('post', REACTIONS.REVIEWS.CREATE, data);
};

export const getProvincesOfCountry = async (countryIso3) => {
  return makeRequest('get', joinBaseUrlWithParams(PROVINCES.COUNTRIES.GET_ALL_OF_COUNTRY, [{param: 'code', value: countryIso3}]));
};

export const checkoutForVisitor = async (data) => {
  const visitor = getOrCreateVisitorId();
  const _data = {
    ...data,
    visitor
  };
  return makeRequest('post', CART.CHECKOUT.VISITOR, _data);
};

export const checkoutAuth = async (data) => {
  return makeRequest('post', CART.CHECKOUT.AUTH, data);
};

export const createBillingAddress = async (data) => {
  return makeRequest('post', PROFILE.ADDRESSES.CREATE, data);
};

export const updateBillingAddress = async (data, id) => {
  return makeRequest('put', joinBaseUrlWithParamsId(PROFILE.ADDRESSES.UPDATE, id), data);
};

export const setDefaultBillingAddress = async (id) => {
  return makeRequest('put', joinBaseUrlWithParamsId(PROFILE.ADDRESSES.SET_DEFAULT, id));
};

export const payOrder = async (orderId, data) => {
  return makeRequest('post', joinBaseUrlWithParamsId(PAYMENT.ORDER, orderId), data);
};

export const checkoutAndPay = async (data) => {
  const visitor = getOrCreateVisitorId();
  const authUser = store.getState().authUser;
  const _data = {...data};
  if (authUser.data) {
    _data.userId = authUser.data.id;
  } else _data.visitor = visitor;

  return makeRequest('post', CART.CHECKOUT.MAIN, _data, {shouldSkipCountryIso: true});
};

export const addToWhishlist = (productId) => {
  const data  = {
    productId,
  };
  return makeRequest('post', REACTIONS.WHISHLIST.ADD_ONE, data);
};

export const removeFromWhishlist = (productId) => {
  const data  = {
      productId,
  };
  return makeRequest('post', REACTIONS.WHISHLIST.REMOVE_ONE, data);
};

export const getOneOrder = async (id) => {
  return await makeRequest('get', joinBaseUrlWithParamsId(PROFILE.ORDERS.GET_ONE, id))
};

export const toggleLikes = (productId) => {
  return makeRequest('post', REACTIONS.LIKES.LIKE_UNLIKE, {productId});
};

export const getProductsByCategory = (categoryId) => {
  return makeRequest('get', joinBaseUrlWithParamsId(PRODUCTS.GET_ALL_BY.CATEGORIES, categoryId));
};

export const getRelatedProducts = (productId) => {
  return makeRequest('get', joinBaseUrlWithParamsId(PRODUCTS.GET_ALL_BY.RELATED, productId));
};

export const updateUserProfile = async (data) => {
  return makeRequest('put', PROFILE.UPDATE_INFOS , data);
};

export const updateUserPassword = async (data) => {
  return makeRequest('put', PROFILE.UPDATE_PASSWORD, data);
};

export const updateUserShippingAdress = async (data) => {
  return makeRequest('put', PROFILE.UPDATE_SHIPPING, data);
};

export const getBannerInfo = async () => {
  return makeRequest('get', HOMEPAGE.BANNER);
};

export const getPartnerInfo = async () => {
  return makeRequest('get', HOMEPAGE.PARTNER);
};

export const getProductsForward = async () => {
  return makeRequest('get', HOMEPAGE.PRODUCTS_FORWARD);
};

export const getProductsFeatured = async () => {
  return makeRequest('get', HOMEPAGE.PRODUCTS_FEATURED);
};

export const getProductOnSales = async () => {
  return makeRequest('get', HOMEPAGE.PRODUCTS_ON_SALES);
};

export const getProductOnRates = async () => {
  return makeRequest('get', HOMEPAGE.PRODUCTS_ON_RATES);
};

export const getProductOnSpecialFeatured = async () => {
  return makeRequest('get', HOMEPAGE.PRODUCTS_SPECIAL_FEATURED);
};

export const getProductMostPurchased = async () => {
  return makeRequest('get', HOMEPAGE.PRODUCTS_MOST_PURCHASED);
};

export const addBannerCategory = async (data) => {
  return makeRequest('post', SETTINGS.BANNER.CATEGORIES.CREATE, data, { fileData: ['image'], multipart: true });
};

export const updateBannerCategory = async (id, data) => {
  return makeRequest('put', joinBaseUrlWithParamsId(SETTINGS.BANNER.CATEGORIES.UPDATE, id), data, { fileData: ['image'], multipart: true });
};

export const deleteBannerCategory = async (id) => {
  return makeRequest('delete', joinBaseUrlWithParamsId(SETTINGS.BANNER.CATEGORIES.DELETE, id));
};

export const getProductsInSales = async () => {
  return makeRequest('get', PRODUCTS.ADMIN.SALES.GET_ALL);
};

export const addOrUpdateFeaturedProduct = async (data) => {
  return makeRequest('post', SETTINGS.FEATURED_PRODUCTS.CREATE, data, { fileData: ['image'], multipart: true });
};

export const applyTestSettings = async (data) => {
  return makeRequest('post', SETTINGS.VALIDATION, data);
};

export const addSettingPartner = async (data) => {
  return makeRequest('post', SETTINGS.PARTNERS.CREATE, data, { fileData: ['image'], multipart: true });
};

export const updateSettingPartner = async (id, data) => {
  return makeRequest('put', joinBaseUrlWithParamsId(SETTINGS.PARTNERS.UPDATE, id), data, { fileData: ['image'], multipart: true });
};

export const deleteSettingPartner = async (id) => {
  return makeRequest('delete', joinBaseUrlWithParamsId(SETTINGS.PARTNERS.DELETE, id));
};
