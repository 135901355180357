import React from 'react';

/**
 * Bar loader
 */
const BarLoader = () => {
    return (
        <div>
            <div className="custom-bar-loader">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default BarLoader;
