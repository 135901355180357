import {
	SETTINGS,
	SET_LANGUAGE,
	SETTINGS_FAILURE,
	SETTINGS_SUCCESS,
	SETTINGS_SET_IS_PROD,
} from "../actions/action-types";
import StorageHelper from "../helpers/StorageHelper";
import {getDefaultLanguage} from "../helpers/funcHelper";

/**
 * initial app settings
 */
const INIT_STATE = {
	data: {
		data: null,
		error: null,
		loading: false
	},
	isProd: false,
	language: getDefaultLanguage(),
	// languages: AppConfig.languages,
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		// set language
		case SET_LANGUAGE:
			StorageHelper.setItem(StorageHelper.LOCALE, action.payload.locale);
			return { ...state, language: action.payload };

		case SETTINGS_SET_IS_PROD:
			return { ...state, isProd: !!action.payload };

		case SETTINGS:
			return { ...state, data: {loading: true} };

		case SETTINGS_SUCCESS:
			return { ...state, data: {loading: false, data: action.payload }};

		case SETTINGS_FAILURE:
			return { ...state, data: {loading: false, error: action.payload }};

		default: return { ...state };
	}
}
