import _ from "lodash";
import Type from "../enums/Type";
import ProductVariant from "./ProductVariant";
import FeatureType from "../enums/FeatureType";

export default class Product {
  constructor(product) {
    Object.assign(this, product);

    // Check if it's lightning product in that case do not instantiate ProductVariant
    if (product.defaultProductVariant) {
      this.defaultPV = new ProductVariant(product.defaultProductVariant);
    }

    if (product.productVariantList) {
      this.productVariantList = product.productVariantList.map(pv => new ProductVariant(pv));
    }
  }

  get colors() {
    return this.productVariantList.map(pv => ({...pv.variantFeatures[FeatureType.COLOR], id: pv.id}))
  }

  get sizes() {
    return this.productVariantList.map(pv => ({...pv.variantFeatures[FeatureType.SIZE], id: pv.id}))
  }

  get images() {
    const images = [];
    this.productVariantList
        .map(pv => pv.images)
        .forEach(_images => images.push(..._images));
    return images;
  }

  getSalePrice(price, round = true) {
    let result = price;
    if (this.sale) {
      if (this.sale.saleType === Type.CURRENCY) {
        result = price - this.sale.value;
      } else {
        result = price - (price * this.sale.value) / 100;
      }

      if (result < 0)
        result = 0;
    }

    return round ? _.ceil(result, 2) : result;
  }
}
