import _ from "lodash";
import Type from "../enums/Type";
import CartItem from "./CartItem";
import paymentMethods from "../data/paymentMethods";

export default class Cart {
    constructor(cartItems, voucher, paymentMethod, tax) {
        this.cartItems = cartItems.map(cartItem => new CartItem(cartItem));
        this.totalItems = this.cartItems.length;
        this.voucher = voucher;
        this.paymentMethod = paymentMethod ? paymentMethod : paymentMethods[0];
        this.tax = tax;
    }

    getCartItemByPVId(pvId) {
        return this.cartItems.find(item => item.productVariant.id === pvId);
    }

    getRawTotal(round = true) {
        let totalPrice = 0;
        for (const item of this.cartItems) {
            totalPrice += item.totalPrice;
        }
        return round ? _.ceil(totalPrice, 2) : totalPrice;
    }

    getVoucherCalValue(round = true) {
        let result = 0;
        if (this.voucher) {
            if (this.voucher.type === Type.CURRENCY) {
                result = this.voucher.value;
            } else {
                result = (this.getRawTotal(false) * this.voucher.value) / 100;
            }
        }
        return round ? _.ceil(result, 2) : result;
    }

    getTotalWithVoucher(round = true) {
        let totalPrice = this.getRawTotal(false) - this.getVoucherCalValue(false);

        return round ? _.ceil(totalPrice, 2) : totalPrice;
    }

    getTaxFees(round = true) {
        let result = 0;
        if (this.tax) {
            result = this.getTotalWithVoucher(false) * this.tax.value / 100;
        }

        return round ? _.ceil(result, 2) : result;
    }

    getTotalWithTax(round = true) {
        let result = this.getTotalWithVoucher(false) + this.getTaxFees(false);
        return round ? _.ceil(result, 2) : result;
    }

    getPaymentFees(round = true) {
        let result = 0;
        if (this.paymentMethod) {
            result = (this.paymentMethod.fees / (100 - this.paymentMethod.fees)) * this.getTotalWithTax(false);
        }
        return round ? _.ceil(result, 2) : result;
    }

    getParticularPaymentFees(paymentMethod, round = true) {
        let result = 0;
        if (paymentMethod) {
            result = (paymentMethod.fees / (100 - paymentMethod.fees)) * this.getTotalWithTax(false);
        }
        return round ? _.ceil(result, 2) : result;
    }

    getTotal(round = true) {
        let totalPrice = this.getTotalWithTax(false) + this.getPaymentFees(false);

        if (totalPrice <= 0) {
            totalPrice = 0;
        }

        return round ? _.ceil(totalPrice, 2) : totalPrice;
    }

    /**
     * Check if a product variant is into the cart
     * @param productVariantId
     */
    gotPV(productVariantId) {
        return this.cartItems.findIndex(cartItem => cartItem.productVariant.id === productVariantId) !== -1;
    }
}
