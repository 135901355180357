import dayjs from "dayjs";
import AppLocale from "./lang";
import AppRoute from './routes';
import 'react-app-polyfill/ie11';
import {connect} from 'react-redux';
import {IntlProvider} from "react-intl";
import 'react-slidedown/lib/slidedown.css';
import {BrowserRouter} from "react-router-dom";
import React, {useEffect, useMemo} from 'react';
import {setAuthUser} from "./actions/authActions";
import 'react-notifications/lib/notifications.css';
import {getUserOrVisitorGeoAddress} from "./actions";
import {NotificationContainer} from 'react-notifications';
import RequestGlobalLoader from "./components/RequestGlobalLoader";
import LoadingOverlay from "./components/features/loading-overlay";
import StickySettingBox from "./components/StickySettingBox";

const localizedFormat = require('dayjs/plugin/localizedFormat');
const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

function Main({settings, authUser, geoAddress, setAuthUser, getUserOrVisitorGeoAddress}) {

    useEffect(() => {
        setAuthUser();
        getUserOrVisitorGeoAddress();
    }, []);

    const currentAppLocale = useMemo(() => {
        let currentAppLocale = AppLocale[settings.language.locale];

        if (!currentAppLocale) {
            currentAppLocale = AppLocale.en;
        }

        return currentAppLocale;
    }, [settings.language]);

    if (authUser.loading || geoAddress.loading) {
        return <LoadingOverlay />;
    }

    return (
        <IntlProvider
            key={currentAppLocale.locale}
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
        >
            <>
                <NotificationContainer />
                <RequestGlobalLoader />
                <StickySettingBox />
                <BrowserRouter basename={ '/' } >
                    <AppRoute />
                </BrowserRouter>
            </>
        </IntlProvider>
    );
}

export default connect(
    ({settings, authUser, geoAddress}) => ({settings, authUser, geoAddress}),
    {setAuthUser, getUserOrVisitorGeoAddress})(Main);
