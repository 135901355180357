import BaseEnum from './BaseEnum';

export default class Type extends BaseEnum {
    static PERCENT = 'PERCENT';
    static CURRENCY = 'CURRENCY';
    static ADD = 'ADD';
    static REMOVE = 'REMOVE';
    static LIKE = 'LIKE';
    static UNLIKE = 'UNLIKE';
    static BANNER = 'BANNER';
    static PARTNER = 'PARTNER';
    static DEAL = 'DEAL';
    static SLIDER = 'SLIDER';
    static PROMO = 'PROMO';
    static SPECIAL = 'SPECIAL';
}
