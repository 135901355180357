import {
  ORDERS,
  COLORS,
  PRODUCTS,
  SETTINGS,
  WHISHLIST,
  CATEGORIES,
  GEO_ADDRESS,
  BILLING_ADDRESSES,
  SET_REQUEST_GLOBAL_LOADER,
} from './action-types';
import {
  THIRD_PARTIES,
  COLORS as COLORS_API,
  PROFILE as PROFILE_API,
  SETTINGS as SETTINGS_API,
  PRODUCTS as PRODUCTS_API,
  REACTIONS as REACTIONS_API,
  CATEGORIES as CATEGORIES_API,
} from '../urls/backendUrl';
import store from "../store";
import {getOrCreateVisitorId, makeActionRequest} from '../helpers/funcHelper';
import CountryManager from "../helpers/CountryManager";
import StorageHelper from "../helpers/StorageHelper";
import SortDirectionType from "../enums/SortDirectionType";

export const getCategories = () => (dispatch) => {
  const url = `${CATEGORIES_API.GET_ALL}`;
  return makeActionRequest('get', url, CATEGORIES, dispatch);
};

export const getColors = () => (dispatch) => {
  const url = `${COLORS_API.GET_ALL}`;
  return makeActionRequest('get', url, COLORS, dispatch);
};

export const getProducts = (searchFilter) => dispatch => {
  const data = {};
  let url = `${PRODUCTS_API.GET_ALL}`;
  const visitor = getOrCreateVisitorId();
  const authUser = store.getState().authUser.data;

  if (!authUser) {
    data.visitor = visitor;
  } else data.userId = authUser.id;

  data.min_price = searchFilter.filters.priceRange.min;
  data.max_price = searchFilter.filters.priceRange.max;

  if (searchFilter.filters.categories.length > 0)
    data.category_list = JSON.stringify(searchFilter.filters.categories);

  if (searchFilter.filters.colors.length > 0)
    data.color_list = JSON.stringify(searchFilter.filters.colors);

  data.page = searchFilter.pagination.page;
  data.size = searchFilter.pagination.size;

  if (searchFilter.query)
    data.query = searchFilter.query;

  data.sField = searchFilter.sort.field === SortDirectionType.CREATION_DATE ? 'c_date' : searchFilter.sort.field;
  data.sDirection = searchFilter.sort.direction.toLowerCase();

  return makeActionRequest('get', url, PRODUCTS, dispatch, data);
};

export const getBillingAddresses = () => (dispatch) => {
  const url = `${PROFILE_API.ADDRESSES.GET_ALL}`;
  return makeActionRequest('get', url, BILLING_ADDRESSES, dispatch);
};

export const getOrders = () => (dispatch) => {
  const url = `${PROFILE_API.ORDERS.GET_ALL}`;
  return makeActionRequest('get', url, ORDERS, dispatch);
};

export const getWhishlist = () => (dispatch) => {
  const url = `${REACTIONS_API.WHISHLIST.GET_ALL}`;
  return makeActionRequest('get', url, WHISHLIST, dispatch);
};

export const getTestSettings = () => (dispatch) => {
  const url = `${SETTINGS_API.TEST}`;
  return makeActionRequest('get', url, SETTINGS, dispatch);
};

export const getUserOrVisitorGeoAddress = () => (dispatch) => {
  return makeActionRequest('get', THIRD_PARTIES.GEO_ADDRESS, GEO_ADDRESS, dispatch, null, {skipError: true})
      .then(result => {
        if (result && result.countryCode) {
          let countryAlpha3Code = "CAN";
          // let countryAlpha3Code = result.countryCode;
          if (countryAlpha3Code.length < 3) {
            const _country = CountryManager.getCountryFromAlpha2Code(result.countryCode);
            if (_country) {
              countryAlpha3Code = _country.alpha3Code;
            }
          }
          StorageHelper.setItem(StorageHelper.COUNTRY_ALPHA_3_CODE, countryAlpha3Code);
          StorageHelper.setItem(StorageHelper.COUNTRY_REGION_NAME, "Alberta");
          // StorageHelper.setItem(StorageHelper.COUNTRY_REGION_NAME, result.regionName);
        }
      })
      .catch(() => null);
};
