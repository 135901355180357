import {connect} from "react-redux";
import { scrollTop } from '../utils';
import React, { useEffect } from 'react';
import LoadingOverlay from '../components/features/loading-overlay';

const HomePage = React.lazy(() => import('../components/home'));
const AuthPage = React.lazy(() => import('../components/pages/others/auth'));

function AppRoot({authUser}) {
    useEffect(() => {
        scrollTop();

        // Remove the overlay set into the index.html file
        document.querySelector('body').classList.add("loaded");
        document.querySelector("#root").classList.add( "loaded");
    }, []);

    return (
        <React.Suspense fallback={<LoadingOverlay /> }>
            {authUser ? (
                <HomePage />
            ) : (
                <AuthPage/>
            )}
        </React.Suspense>
    )
}

export const mapStateToProps = ({authUser}) => {
    return {
        authUser: authUser.data,
    }
};

export default React.memo(connect(mapStateToProps, {})(AppRoot));
