// Get Products
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';

// Refresh Store
export const REFRESH_STORE = 'REFRESH_STORE';

// Quick view and single product
export const SHOW_QUICK_VIEW = 'SHOW_QUICK_VIEW';
export const CLOSE_QUICK_VIEW = 'CLOSE_QUICK_VIEW';
export const SET_CURRENT_PV = 'SET_CURRENT_PV';
export const SINGLE_PRODUCT = 'SINGLE_PRODUCT';
export const SINGLE_PRODUCT_SUCCESS = 'SINGLE_PRODUCT_SUCCESS';
export const SINGLE_PRODUCT_FAILURE = 'SINGLE_PRODUCT_FAILURE';

// Cart
export const SET_CART_ITEMS = 'SET_CART_ITEMS';
export const SET_CART_TAX = 'SET_CART_TAX';
export const SET_VOUCHER = 'SET_VOUCHER';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const CHANGE_QTY = 'CHANGE_QTY';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CHANGE_SHIPPING = 'CHANGE_SHIPPING';

// WishList
export const TOGGLE_WISHLIST = 'TOGGLE_WISHLIST';
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';

// Compare
export const ADD_TO_COMPARE = 'ADD_TO_COMPARE';
export const REMOVE_FROM_COMPARE = 'REMOVE_FROM_COMPARE';
export const RESET_COMPARE = 'RESET_COMPARE';

//Product Filters
export const SORT_BY = 'SORT_BY';
export const CATEGORY_FILTER = 'CATEGORY_FILTER';
export const RESET_FILTER = 'RESET_FILTER';
export const SIZE_FILTER = 'SIZE_FILTER';
export const COLOR_FILTER = 'COLOR_FILTER';
export const BRAND_FILTER = 'BRAND_FILTER';
export const PRICE_FILTER = 'PRICE_FILTER';
export const RATING_FILTER = 'RATING_FILTER';

//Newsletter
export const HIDE_NEWSLETTER_MODAL = 'HIDE_NEWSLETTER_MODAL';

// Modal
export const SHOW_MODAL = 'SHOW_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const REMOVE_NEWSLETTER = 'REMOVE_NESLETTER';


/* SETTINGS */
export const SET_LANGUAGE = 'SET_LANGUAGE';

/* REQUEST PAGE LOADER */
export const SET_REQUEST_GLOBAL_LOADER = 'SET_REQUEST_GLOBAL_LOADER';

/* AUTH */
export const SET_AUTH_DATA = 'SET_AUTH_DATA';
export const AUTH_DATA = 'AUTH_DATA';
export const AUTH_DATA_SUCCESS = 'AUTH_DATA_SUCCESS';
export const AUTH_DATA_FAILURE = 'AUTH_DATA_FAILURE';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
    'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* TODOAPP */
export const TODO_GET_LIST = 'TODO_GET_LIST';
export const TODO_GET_LIST_SUCCESS = 'TODO_GET_LIST_SUCCESS';
export const TODO_GET_LIST_ERROR = 'TODO_GET_LIST_ERROR';
export const TODO_GET_LIST_WITH_FILTER = 'TODO_GET_LIST_WITH_FILTER';
export const TODO_GET_LIST_WITH_ORDER = 'TODO_GET_LIST_WITH_ORDER';
export const TODO_GET_LIST_SEARCH = 'TODO_GET_LIST_SEARCH';
export const TODO_ADD_ITEM = 'TODO_ADD_ITEM';
export const TODO_ADD_ITEM_SUCCESS = 'TODO_ADD_ITEM_SUCCESS';
export const TODO_ADD_ITEM_ERROR = 'TODO_ADD_ITEM_ERROR';
export const TODO_SELECTED_ITEMS_CHANGE = 'TODO_SELECTED_ITEMS_CHANGE';

/* CHAT APP */
export const CHAT_GET_CONTACTS = 'CHAT_GET_CONTACTS';
export const CHAT_GET_CONTACTS_SUCCESS = 'CHAT_GET_CONTACTS_SUCCESS';
export const CHAT_GET_CONTACTS_ERROR = 'CHAT_GET_CONTACTS_ERROR';
export const CHAT_GET_CONVERSATIONS = 'CHAT_GET_CONVERSATIONS';
export const CHAT_GET_CONVERSATIONS_SUCCESS = 'CHAT_GET_CONVERSATIONS_SUCCESS';
export const CHAT_GET_CONVERSATIONS_ERROR = 'CHAT_GET_CONVERSATIONS_ERROR';
export const CHAT_ADD_MESSAGE_TO_CONVERSATION =
    'CHAT_ADD_MESSAGE_TO_CONVERSATION';
export const CHAT_CREATE_CONVERSATION = 'CHAT_CREATE_CONVERSATION';
export const CHAT_SEARCH_CONTACT = 'CHAT_SEARCH_CONTACT';
export const CHAT_CHANGE_CONVERSATION = 'CHAT_CHANGE_CONVERSATION';

/* SURVEY LIST APP */
export const SURVEY_LIST_GET_LIST = 'SURVEY_LIST_GET_LIST';
export const SURVEY_LIST_GET_LIST_SUCCESS = 'SURVEY_LIST_GET_LIST_SUCCESS';
export const SURVEY_LIST_GET_LIST_ERROR = 'SURVEY_LIST_GET_LIST_ERROR';
export const SURVEY_LIST_GET_LIST_WITH_FILTER =
    'SURVEY_LIST_GET_LIST_WITH_FILTER';
export const SURVEY_LIST_GET_LIST_WITH_ORDER =
    'SURVEY_LIST_GET_LIST_WITH_ORDER';
export const SURVEY_LIST_GET_LIST_SEARCH = 'SURVEY_LIST_GET_LIST_SEARCH';
export const SURVEY_LIST_ADD_ITEM = 'SURVEY_LIST_ADD_ITEM';
export const SURVEY_LIST_ADD_ITEM_SUCCESS = 'SURVEY_LIST_ADD_ITEM_SUCCESS';
export const SURVEY_LIST_ADD_ITEM_ERROR = 'SURVEY_LIST_ADD_ITEM_ERROR';
export const SURVEY_LIST_SELECTED_ITEMS_CHANGE =
    'SURVEY_LIST_SELECTED_ITEMS_CHANGE';

/* SURVEY DETAIL APP */
export const SURVEY_GET_DETAILS = 'SURVEY_GET_DETAILS';
export const SURVEY_GET_DETAILS_SUCCESS = 'SURVEY_GET_DETAILS_SUCCESS';
export const SURVEY_GET_DETAILS_ERROR = 'SURVEY_GET_DETAILS_ERROR';
export const SURVEY_DELETE_QUESTION = 'SURVEY_DELETE_QUESTION';
export const SURVEY_SAVE = 'SURVEY_SAVE';

export const CATEGORIES = 'CATEGORIES';
export const CATEGORIES_SUCCESS = 'CATEGORIES_SUCCESS';
export const CATEGORIES_FAILURE = 'CATEGORIES_FAILURE';

export const COLORS = 'COLORS';
export const COLORS_SUCCESS = 'COLORS_SUCCESS';
export const COLORS_FAILURE = 'COLORS_FAILURE';

export const PRODUCTS = 'PRODUCTS';
export const PRODUCTS_SUCCESS = 'PRODUCTS_SUCCESS';
export const PRODUCTS_FAILURE = 'PRODUCTS_FAILURE';
export const PRODUCTS_UPDATE_ONE = 'PRODUCTS_UPDATE_ONE';

export const BILLING_ADDRESSES = 'BILLING_ADDRESSES';
export const BILLING_ADDRESSES_SUCCESS = 'BILLING_ADDRESSES_SUCCESS';
export const BILLING_ADDRESSES_FAILURE = 'BILLING_ADDRESSES_FAILURE';

export const ORDERS = 'ORDERS';
export const ORDERS_SUCCESS = 'ORDERS_SUCCESS';
export const ORDERS_FAILURE = 'ORDERS_FAILURE';

export const WHISHLIST = 'WHISHLIST';
export const WHISHLIST_SUCCESS = 'WHISHLIST_SUCCESS';
export const WHISHLIST_FAILURE = 'WHISHLIST_FAILURE';

export const GEO_ADDRESS = 'GEO_ADDRESS';
export const GEO_ADDRESS_SUCCESS = 'GEO_ADDRESS_SUCCESS';
export const GEO_ADDRESS_FAILURE = 'GEO_ADDRESS_FAILURE';

//Search_ filter
export const SF__QUERY_CHANGED = 'SF__QUERY_CHANGED';
export const SF__FILTER_CATEGORY_CLEARED = 'SF__FILTER_CATEGORY_CLEARED';
export const SF__FILTER_CATEGORY_ADDED = 'SF__FILTER_CATEGORY_ADDED';
export const SF__FILTER_CATEGORY_REMOVED = 'SF__FILTER_CATEGORY_REMOVED';
export const SF__FILTER_CATEGORY_TOGGLE = 'SF__FILTER_CATEGORY_TOGGLE';
export const SF_PAGINATE_CHANGE = 'SF_PAGINATE_CHANGE';
export const SF__FILTER_COLOR_TOGGLE = 'SF__FILTER_COLOR_TOGGLE';
export const SF__FILTER_COLOR_ADDED = 'SF__FILTER_COLOR_ADDED';
export const SF__FILTER_COLOR_REMOVED = 'SF__FILTER_COLOR_REMOVED';
export const SF__FILTER_PRICE_RANGE = 'SF__FILTER_PRICE_RANGE';
export const SF__SORT_FIELD = 'SF__SORT_FIELD';
export const SF__SORT_PRICE = 'SF__SORT_PRICE';
export const SF__SORT_CREATION_DATE = 'SF__SORT_CREATION_DATE';
export const SF__SORT_DIRECTION = 'SF__SORT_DIRECTION';

export const SETTINGS = 'SETTINGS';
export const SETTINGS_SUCCESS = 'SETTINGS_SUCCESS';
export const SETTINGS_FAILURE = 'SETTINGS_FAILURE';
export const SETTINGS_SET_IS_PROD = 'SETTINGS_SET_IS_PROD';

