import { RECEIVE_PRODUCTS } from "../actions/action-types";
import { findIndex } from "../utils";

import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

const initialState = {
    products: [],
    productDetail: [],
    quickView: false
};

const productReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case RECEIVE_PRODUCTS:
            return {
                ...state,
                products: action.products
            };

        default:
            return state;
    }
};
const persistConfig = {
    keyPrefix: "molla-",
    key: "products",
    storage
}

export default persistReducer( persistConfig, productReducer );
