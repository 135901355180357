import {connect} from "react-redux";
import React, {useState} from 'react';
import {injectIntl} from "react-intl";
import ConfirmBox from "./dialog/ConfirmBox";
import Switch from "@material-ui/core/Switch";
import {NotificationManager} from "react-notifications";
import {isSettingProdMode} from "../helpers/funcHelper";
import {applyTestSettings, setRequestGlobalAction} from "../actions";

const StickySettingBox = ({setRequestGlobalAction}) => {
    const isProdMode = isSettingProdMode();

    const [show, setShow] = useState();
    const [showConfirmBox, setShowConfirmBox] = useState(false);

    const onToggleView = e => {
        e.preventDefault();
        setShow(!show);
    };

    const handleChange = () => {
        window.location = `${window.location.origin}${!isProdMode ? '?mode=prod' : ''}`;
    };

    const onApplyChanges = (e) => {
        e.preventDefault();
        setRequestGlobalAction(true);
        applyTestSettings()
            .then(() => {
                NotificationManager.success("Changes applied successfully. Reloading...");
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            })
            .catch(() => null)
            .finally(() => setRequestGlobalAction(false));
    };

    return (
        <>
            <div className={`sticky-setting ${show ? 'shown' : 'hidden'}`}>
                <div className="px-4 py-4 row center-hor-ver">
                    <div className="col-sm-12 border-bottom pb-2">
                        <div className="row">
                            <div className="col-md-10">
                                <p className="">{isProdMode ? "Go back to test mode" : "Show the result (View test data as if it was in production)"}</p>
                            </div>
                            <div className="col-md-2">
                                <Switch
                                    onChange={handleChange}
                                    defaultChecked={isProdMode}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="center-hor col-sm-12 mt-2 px-0">
                        <a
                            href="#"
                            className="btn btn-danger"
                            onClick={() => setShowConfirmBox(true)}>
                            Apply changes to production
                        </a>
                    </div>
                </div>
                <a href="#" className="toggler" onClick={onToggleView}>
                    <i className="simple-icon-settings" />
                </a>
            </div>
            <ConfirmBox
                show={showConfirmBox}
                rightButtonOnClick={onApplyChanges}
                leftButtonOnClick={() => setShowConfirmBox(false)}
                message="Do you really want to apply these changes to the production website ?"
            />
        </>
    );
};

StickySettingBox.propTypes = {

};

export default connect(() => ({}), {setRequestGlobalAction})(injectIntl(StickySettingBox));
