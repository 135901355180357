import * as Constants from './../constants/defaultValues';

export const BASE = `${Constants.backendUrl.baseUrl}`;

const shouldHavePublic = false;

const publicPrefix = shouldHavePublic ? 'public/' : '';

export const AUTH = {
    REGISTER: '/auth/register',
    LOGIN: '/oauth/token',
    ME: 'auth/me',
};

export const CATEGORIES = {
    GET_ALL: '/api/marketplace/products/categories',
    UPDATE_STATUS: '/urls/admin/categories/{id}/status',
    GET_ALL_ROOT: '/urls/admin/categories/root',
};

export const PRODUCTS = {
    GET_ALL: '/api/marketplace/products/research',
    GET_ONE: '/api/marketplace/products/{id}',
    GET_ALL_BY: {
        RELATED: '/api/marketplace/products/{id}/related',
        // RELATED: '/api/marketplace/products',
        CATEGORIES: '/api/marketplace/products/category/{id}'
    },
    ADMIN: {
        SALES: {
            GET_ALL: '/api/admin/settings/sales/products'
        }
    }
};

export const PROFILE = {
    UPDATE_INFOS: '/api/users',
    UPDATE_PASSWORD: '/api/users/update/password',
    UPDATE_SHIPPING: '/api/users/address',
    LANGUAGE: {
        UPDATE: '/api/users/update/language'
    },
    ADDRESSES: {
        CREATE: '/api/users/addresses',
        GET_ALL: '/api/users/addresses',
        GET_ONE: '/api/users/addresses/{id}',
        UPDATE: '/api/users/addresses/{id}',
        SET_DEFAULT: '/api/users/addresses/{id}/default',
        DELETE: '/api/users/addresses/{id}',
    },
    ORDERS: {
        GET_ALL: '/api/users/orders',
        GET_ONE: '/api/users/orders/{id}',
    }
};

export const COLORS = {
    GET_ALL: '/api/marketplace/variants/features/color',
};

export const CART = {
    ADD_ITEM: '/api/users/cart',
    UPDATE_ITEM: '/api/users/cart',
    REMOVE_ITEM: '/api/users/cart/items/remove',
    REMOVE_ALL: '/api/users/cart/items/clear',
    GET_ALL: '/api/users/cart',
    NORMALIZE: '/api/users/cart/{id}/normalize',
    CHECKOUT: {
        MAIN: '/api/users/cart/checkout',
        AUTH: '/api/users/cart/checkout/user',
        VISITOR: '/api/users/cart/checkout/visitor',
    }
};

export const MARKET_PLACE = {
    COUPON: {
        CHECK: '/api/marketplace/vouchers/{code}/check'
    }
};

export const PROVINCES = {
    UPDATE: '/api/users/provinces/{id}',
    GET_ALL: '/api/users/provinces',
    COUNTRIES: {
        GET_ALL: '/api/users/provinces/countries',
        GET_ALL_OF_COUNTRY: '/api/users/provinces/countries/{code}',
    },
};

export const REACTIONS = {
    WHISHLIST: {
        GET_ALL: '/api/reactions/wishList',
        ADD_ONE: '/api/reactions/wish/add',
        REMOVE_ONE: '/api/reactions/wish/remove',
    },
    REVIEWS: {
        CREATE: '/api/reactions/reviews',
        GET_ALL: '/api/reactions/reviews/products/{id}',
    },
    LIKES: {
        GET_ALL: '/api/reactions/likes/product/{id}',
        LIKE_UNLIKE: '/api/reactions/likes'
    }
};

export const PAYMENT = {
    STRIPE: "/api/payments/stripe",
    ORDER: "/api/users/cart/checkout/order/{id}",
};

export const HOMEPAGE = {
    BANNER: '/api/settings/banners',
    PARTNER: '/api/settings/partners',
    PRODUCTS_FORWARD: '/api/settings/featured/products',
    PRODUCTS_FEATURED: '/api/settings/products/featured',
    PRODUCTS_ON_SALES: '/api/settings/products/featured/sale',
    PRODUCTS_MOST_PURCHASED: '/api/settings/products/purchased',
    PRODUCTS_ON_RATES: '/api/settings/products/rated',
    PRODUCTS_SPECIAL_FEATURED: 'api/homepage/products_special_feature',
};

export const THIRD_PARTIES = {
    GEO_ADDRESS: "https://geo.buyamshop.com"
};

export const SETTINGS = {
    TEST: '/api/settings/test',
    PROD: '/api/settings/prod',
    VALIDATION: '/api/settings/validation',
    BANNER: {
        CATEGORIES: {
            CREATE: '/api/admin/settings/banners/category',
            UPDATE: '/api/admin/settings/banners/{id}/category',
            DELETE: '/api/admin/settings/banners/{id}/category',
        }
    },
    FEATURED_PRODUCTS: {
        CREATE: "/api/admin/settings/featured/products",
        UPDATE: "/api/admin/settings/featured/products",
    },
    PARTNERS: {
        CREATE: '/api/admin/settings/partners',
        UPDATE: '/api/admin/settings/partners/{id}',
        DELETE: '/api/admin/settings/partners/{id}',
    }
};

export const joinBaseUrl = to => BASE + to;

export const joinBaseUrlWithParams = (to, params) => {
    let url = BASE + to;

    params.forEach(param => {
        url = url.replace(`{${param.param}}`, `${encodeURIComponent(param.value)}`);
    });

    return url;
};

export const joinBaseUrlWithRequestParams = (to, params) => {
    let url = BASE + to;
    let i = 0;
    params.forEach(param => {
        if(i === 0)
            url = url + '?' + param.param + '=' + param.value
        else
        url = url + '&' + param.param + '=' + param.value
    });

    return url;
};

export const joinBaseUrlWithParamsId = (to, id) => {
    return joinBaseUrlWithParams(to, [{param: 'id', value: id}]);
};
