import axios from 'axios';
import {formatMessage} from "../lang";
import {errorManager} from "./errorHelper";
import StorageHelper from "./StorageHelper";
import {NotificationManager} from "react-notifications";
import * as Constants from './../constants/defaultValues';
import {ERROR_401, ERROR_403, ERROR_404, ERROR_500, ERROR_UNKNOWN} from "../data/errors";
import { getAuthToken, removeAuthToken, saveAuthToken } from "./userHelper";
import {getUserIdOrVisitorId, objectToFormData, toCamelCase, toSnakeCase} from "./funcHelper";

const customAxios =
  axios.create({
    baseURL: Constants.backendUrl.baseUrl,
    timeout: Constants.backendUrl.timeout
  });

customAxios.interceptors.request.use(
  async (config) => {
    try {
      /*console.log("config => ", config);
      let url = config.url.split('?');
      // let search = url.length > 1 ? url[1] : url[0];
      const result = getUserIdOrVisitorId();
      if (result.userId) {
        if (url.includes('userId')) {
          url.
        }
      }*/
      // Get auth token
      const { accessToken } = getAuthToken();

      if (accessToken && !config.shouldSkipToken) {
        config.headers['Authorization'] = 'Bearer ' + accessToken;
      }

      const countryIso = StorageHelper.getItem(StorageHelper.COUNTRY_ALPHA_3_CODE);
      const provinceName = StorageHelper.getItem(StorageHelper.COUNTRY_REGION_NAME);

      if (countryIso && !config.shouldSkipCountryIso) {
        let url = config.url;
        if (url.includes('?'))
          url += `&country_iso=${countryIso}`;
        else url += `?country_iso=${countryIso}`;

        url += `&province_name=${provinceName}`;

        // Update main url
        config.url = url;
      }

      if (config.multipart) {
        config.headers['content-type'] = 'multipart/form-data';
      }

      // Check if post or put to perform some operation
      if ((config.method === 'post' || config.method === 'put') && !config.shouldSkipDataParsing) {
        // Create an object to store file data
        const fileData = {};

        // Check if fileData is present
        if (config.fileData) {
          config.fileData.forEach(f => {
            fileData[f] = config.data[f];
            delete config.data[f];
          });
        }
        // Parse object to snakeCase and Form data
        const data = toSnakeCase(config.data);
        config.data = objectToFormData(data);

        // Append files to data to send
        if (config.fileData) {
          Object.entries(fileData).forEach(item => {
            config.data.append(item[0], item[1]);
          });
        }
      }

      // config.headers['Content-Type'] = 'application/json';
      return config;
    } catch (e) {
      return config;
    }

  },
  error => Promise.reject(error));

customAxios.interceptors.response.use(
  response => {
    if (response && response.data) {
      if (Array.isArray(response.data)) {
        response.data = response.data.map(item => toCamelCase(item));
      } else if (response.data.hasOwnProperty('data')) {
        if (Array.isArray(response.data.data)) {
          // @ts-ignore
          response.data.data = response.data.data.map(item => toCamelCase(item));
        } else response.data.data = toCamelCase(response.data.data);
      } else response.data = toCamelCase(response.data);
    }

    return response;
  },
  error => {
    const originalRequest = error.config;
    if (error) {
      if (error.response) {
        if (!originalRequest.skipError) {
          switch (error.response.status) {
            case 400:
              errorManager(error.response.data.errorss);
              return Promise.reject(error);
            case 401:
              NotificationManager.error(formatMessage(ERROR_401));
              return Promise.reject(error);
            case 403:
              NotificationManager.error(formatMessage(ERROR_403));
              return Promise.reject(error);
            case 404:
              NotificationManager.error(formatMessage(ERROR_404));
              return Promise.reject(error);
            case 500:
              NotificationManager.error(formatMessage(ERROR_500));
              return Promise.reject(error);
            default:
              NotificationManager.error(formatMessage(ERROR_500));
              return Promise.reject(error);
          }
        }
      } else if (!originalRequest.skipError) NotificationManager.error(formatMessage(ERROR_UNKNOWN));
    } else if (!originalRequest.skipError) NotificationManager.error(formatMessage(ERROR_UNKNOWN));

    return Promise.reject(error);
  });

export default customAxios;
