import React from "react";
import {getImageUrl} from "../helpers/funcHelper";
import PaymentMethod from "../enums/PaymentMethod";

export default [
    {
        name: 'Credit Card (Stripe)',
        type: PaymentMethod.STRIPE,
        fees: Number(process.env.REACT_APP_STRIPE_FEES),
        description: (
            <div>
                <img src={getImageUrl("payments-summary.png")} alt="payments cards" />
                Donec nec justo eget felis facilisis fermentum.Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Lorem ipsum dolor sit ame.
            </div>
        ),
    },
    /*{
        name: 'Paypal',
        type: PaymentMethod.PAYPAL,
        // fees: Number(process.env.REACT_APP_PAYPAL_FEES)
        fees: 2,
        description: (
            <div>
                Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum.
            </div>
        ),
    },*/
]
