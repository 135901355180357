import {
    CATEGORIES,
    CATEGORIES_SUCCESS,
    CATEGORIES_FAILURE,
} from '../actions/action-types';

/**
 * initial state
 */
const INIT_STATE = {
    data: null,
    error: null,
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case CATEGORIES:
            return { ...state, loading: true };

        case CATEGORIES_SUCCESS:
            return { ...state, loading: false, data: action.payload };

        case CATEGORIES_FAILURE:
            return { ...state, loading: false, error: action.payload };

        default: return { ...state };
    }
}
