import Main from "./Main";
import dayjs from 'dayjs';
import 'react-app-polyfill/ie11';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { initFunctions } from './utils';
import React, { useEffect } from 'react';
import 'react-slidedown/lib/slidedown.css';
import store from './store';
import 'react-notifications/lib/notifications.css';

const localizedFormat = require('dayjs/plugin/localizedFormat');
const  relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

export function Root() {
    useEffect(() => {
        initFunctions();
    }, []);

    return (
        <Provider store={store}>
            <Main />
        </Provider>
    );
}


ReactDOM.render( <Root />, document.getElementById( 'root' ) );
