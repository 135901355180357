module.exports = {
   "billing.address.found": "{count, plural, =0 {No billing address found} one {1 billing address found} other { {count} billing addresses found}}",
   "billing.address.create.success": "Billing address created successfully",
   "billing.address.update.success": "Billing address updated successfully",
   "orders.found": "{count, plural, =0 {No orders found} one {1 order found} other { {count} orders found}}",
   "payment.success.page.title": "Payment done successfully",
   "payment.success.page.message": "We have sent you a bill containing all information about the payment.",
   "voucher.invalid": "Please enter a valid coupon code",
   "products.found": "{count, plural, =0 {No products found} one {1 product address found} other { {count} products found}}",

   "errors.400.USER_EMAIL_ALREADY_USED": "This email already exists",
   "errors.400.USER_WITH_TOKEN_NOT_FOUND": "No user found with this token",
   "errors.400.USER_WITH_EMAIL_NOT_FOUND": "No user found with this email",
   "errors.400.USER_IDENTITY_NOT_PROOF": "No user found with this email",
   "errors.400.ADDRESS_NOT_FOUND": "This address does not exist",
   "errors.400.PROVINCE_NOT_FOUND": "This province does not exist",
   "errors.400.COUNTRY_NOT_FOUND": "This country does not exist",
   "errors.400.PROVINCE_NAME_ALREADY_EXIST": "A province with this name exists",
   "errors.400.BAD_CATEGORY_PARENT": "A parent category cannot be parent of another category",
   "errors.400.CATEGORY_NAME_ALREADY_EXIST": "A category with this name already exists",
   "errors.400.BAD_CATEGORY_PARENT_OWNER": "A category cannot be its own parent",
   "errors.400.CATEGORY_NOT_FOUND": "This category does not exist",
   "errors.400.CATEGORY_PARENT_NOT_FOUND": "This parent category does not exist",
   "errors.400.PAGE_OR_SIZE_LESS_THAN_ZERO": "The page and size for the pagination must be greater than zero",
   "errors.400.NULL_USER_AND_VISITOR": "You must specify the user or visitor id",
   "errors.400.VISITOR_CART_NOT_FOUND": "The cart of this visitor does not exist",
   "errors.400.CART_NOT_FOUND": "The cart does not exist",
   "errors.400.COUPON_NOT_FOUND": "The coupon does not exist",
   "errors.400.PRODUCT_NOT_FOUND": "The product does not exist",
   "errors.400.PRODUCT_VARIANT_NOT_FOUND": "This variant does not exist",
   "errors.400.QUANTITY_BIGGER_THAN_AVAILABLE": "The quantity of the variant product is no longer available in stock",
   "errors.400.PRODUCT_VARIANT_ALREADY_EXIST": "This variant already exists",
   "errors.400.NO_VARIANT_FEATURE": "The variant product must have at least one feature variant",
   "errors.400.BRAND_NAME_ALREADY_EXIST": "A brand with this name already exists",
   "errors.400.BRAND_NOT_FOUND": "This brand does not exist",
   "errors.400.SALE_NOT_FOUND": "This sale does not exist",
   "errors.400.SALE_ALREADY_EXIST": "A balance is already in progress",
   "errors.400.BAD_ENDING_SALE_DATE": "The end date of the balance must be after its start date",
   "errors.400.VARIANT_FEATURE_NOT_FOUND": "The variant feature does not exist",
   "errors.400.ORDER_NOT_PAID": "The order is not yet paid",
   "errors.400.ORDER_NOT_FOUND": "The order does not exist",
   "errors.400.PARTNER_NOT_FOUND": "Partner does not exist",
   "errors.400.BANNER_NOT_FOUND": "Banner does not exist",
   "errors.400.PRODUCT_ALREADY_IN_WISH_LIST": "This product is already added to the wishlist",
   "errors.400.PRODUCT_IS_NOT_IN_WISH_LIST": "This product is not in the wishlist",
   "errors.400.REVIEW_ALREADY_DONE": "A review is already done on this product",
   "errors.400.BAD_STARS": "The rating must be less than or equal to 5",
   "errors.400.PASSWORD_NOT_MATCH_WITH_OLD": "The old password is not correct",

   "auth.person": "Person",
   "auth.organisation": "Organisation",
   "auth.email": "Email",
   "auth.password": "Password",
   "auth.passwordConfirmation": "Password Confirmation",
   "auth.phoneNumber": "Phone Number",
   "step.step1": "Step 1",
   "step.step2": "Step 2",
   "step.step3": "Step 3",
   "form.name": "Name",
   "form.fileName": "fileName",
   "form.firstName": "First Name",
   "form.lastName": "Last Name",
   "form.manager.firstName": "First Name of manager",
   "form.manager.lastName": "Last Name of manager",
   "form.manager.email": "Email of manager",
   "form.region": "Region",
   "form.regionOrState": "Region / State",
   "form.city": "City",
   "form.address": "Address",
   "form.postalCode": "Postal code",
   "form.country": "Country",
   "form.email": "Email",
   "form.login": "Login",
   "form.password": "Password",
   "form.phoneNumber": "Phone Number",
   "form.subDomain": "Sub domain",
   "form.object": "Object",
   "form.content": "Content",
   "form.min": "Min",
   "form.max": "Max",
   "form.language": "Language",
   "form.acronym": "Acronym",
   "form.insurer": "Insurer",
   "form.description": "Description",
   "form.username": "Username",
   "form.date": "Date",
   "form.startDate": "Start date",
   "form.endDate": "End date",
   "form.birthDate": "Birth date",
   "form.fees": "Fees",
   "form.lateFees": "Late Fees",
   "form.absenceFees": "Absence Fees",
   "form.capacity": "Capacity",
   "form.status": "Status",
   "form.amount": "Amount",
   "form.password.update.success": "Password Updated Successfully",
   "form.password.old": "Old Password",
   "form.password.new": "New Password",
   "form.password.new.confirmation": "Confirm New Password",
   "form.error.verify.name": "Please enter a valid name",
   "form.error.verify.capacity": "Please capacity should be greater than zero",
   "form.error.verify.description": "Please enter a valid description",
   "form.error.verify.price": "Price must be greater than zero",
   "form.error.verify.validityPeriod": "Please enter a correct validity",
   "form.error.verify.address": "Please enter a correct address",
   "form.error.verify.gender": "Please select a gender",
   "form.error.verify.features.min": "Please enter at least one feature",
   "form.error.verify.min": "Please verify min value",
   "form.error.verify.minAndMax": "Please verify min and max value",
   "form.error.correct.min": "Please verify min value",
   "form.error.correct.number": "Please enter a correct number",
   "form.error.requiredField": "This field is required",
   "form.error.validEmail": "Please enter a valid email address",
   "form.error.subDomain": "Please enter a valid sub domain",
   "form.error.validDate": "Please enter a valid date",
   "form.error.minCharacters": "Please enter at least {min} character(s)",
   "form.error.maxCharacters": "Please enter at most {min} character(s)",
   "form.error.passwordConfirmation": "Passwords should be the same",
   "form.error.date.valid": "{date} should be a valid one",
   "form.error.date.min": "You must be more than {minAge} years old to register",
   "form.error.date.maximumDate": "{currentDate} must not be upper than {maximumDate}",
   "form.error.taken.login": "This login is already taken. Please use another one",
   "form.error.taken.email": "This email is already taken. Please use another one",
   "date.validity.start": "Start validity date",
   "date.validity.end": "End validity date",
   "date.birth": "The birth date",
   "date.today": "Today date",
   "auth.createAccount": "Create new account?",
   "auth.haveAccount": "Already have an account?",
   "auth.haveAccountLogin": "Already have an account? Login",
   "auth.login.title": "Get started with {name}",
   "auth.login.subTitle": "Manage your community or group with ease!",
   "auth.register.title": "Get stated with {name}",
   "auth.register.subTitle": "Create your account",
   "auth.termOfService": "By signing up you agree to {name}",
   "auth.signin": "Sign in",
   "auth.signup": "Sign up",
   "auth.resetPasswordLink.btnText": "Reset Password",
   "auth.resetPassword.btnText": "Update Password",
   "auth.resetPasswordLink.title": "Reset your password",
   "auth.resetPasswordLink.subTitle": "Enter your email and we will send you a password reset link",
   "auth.resetPasswordLink.successText": "You have just send you an email. Please check it",
   "auth.resetPassword.title": "Update your password",
   "auth.resetPassword.subTitle": "Enter your new password",
   "auth.resetPassword.successText": "Password reset successfully. Please log in",
   "auth.resetPassword.errorToken": "Bad url params. Please verify your params",
   "error.500": "An error occurred try again later.",
   "common.town": "Town",
   "common.termOfService": "Terms of Service",
   "common.nationality": "Nationality",
   "common.residenceCountry": "Residence country",
   "common.operator": "Operator",
   "common.identificationType": "Identification type",
   "common.identificationNumber": "Identification number",
   "common.socialReason": "Social reason",
   "common.commercialName": "Commercial name",
   "common.organisationType": "Organisation type",
   "common.registrationCountry": "Country of registration",
   "common.registrationType": "Type of registration",
   "common.registrationNumber": "Registration's number",
   "common.registrationBeginningDate": "Registration beginning date",
   "common.agreeTermsConditions": "Agree to Terms & Conditions",
   "common.acceptTermsAndConditions": "You have to accept our terms and conditions",
   "sidebar.app": "App",
   "sidebar.news": "News",
   "sidebar.horizontal": "Horizontal",
   "sidebar.horizontalMenu": "Horizontal Menu",
   "sidebar.general": "General",
   "sidebar.component": "Component",
   "sidebar.features": "Features",
   "sidebar.applications": "Applications",
   "sidebar.dashboard": "Dashboard",
   "sidebar.dashboard1": "Dashboard 1",
   "sidebar.dashboard2": "Dashboard 2",
   "sidebar.dashboard3": "Dashboard 3",
   "sidebar.modules": "Modules",
   "sidebar.agency": "Agency",
   "sidebar.pages": "Pages",
   "sidebar.gallery": "Gallery",
   "sidebar.pricing": "Pricing",
   "sidebar.terms&Conditions": "Terms & Conditions",
   "sidebar.feedback": "Feedback",
   "sidebar.report": "Report",
   "sidebar.faq(s)": "Faq(s)",
   "sidebar.advancedComponent": "Advance Component",
   "sidebar.blank": "Blank",
   "sidebar.session": "Session",
   "sidebar.login": "Login",
   "sidebar.register": "Register",
   "sidebar.lockScreen": "Lock Screen",
   "sidebar.forgotPassword": "Forgot Password",
   "sidebar.404": "404",
   "sidebar.500": "500",
   "sidebar.uiComponents": "UI Components",
   "sidebar.alerts": "Alerts",
   "sidebar.appBar": "App Bar",
   "sidebar.avatars": "Avatars",
   "sidebar.buttons": "Buttons",
   "sidebar.bottomNavigations": "Bottom Navigations",
   "sidebar.badges": "Badges",
   "sidebar.cards": "Cards",
   "sidebar.cardsMasonry": "Cards Masonry",
   "sidebar.chip": "Chip",
   "sidebar.dialog": "Dialog",
   "sidebar.dividers": "Dividers",
   "sidebar.drawers": "Drawers",
   "sidebar.popover": "Popover",
   "sidebar.expansionPanel": "Expansion Panel",
   "sidebar.gridList": "Grid List",
   "sidebar.list": "index.js",
   "sidebar.menu": "Menu",
   "sidebar.popoverAndToolTip": "Pop Over & ToolTip",
   "sidebar.progress": "Progress",
   "sidebar.snackbar": "Snackbar",
   "sidebar.selectionControls": "Selection Controls",
   "sidebar.advanceUiComponents": "Advance UI Components",
   "sidebar.dateAndTimePicker": "Date & Time Picker",
   "sidebar.tabs": "Tabs",
   "sidebar.stepper": "Stepper",
   "sidebar.notification": "Notification",
   "sidebar.sweetAlert": "Sweet Alert",
   "sidebar.autoComplete": "Auto Complete",
   "sidebar.aboutUs": "About Us",
   "sidebar.widgets": "Widgets",
   "sidebar.forms": "Forms",
   "sidebar.formElements": "Form Elements",
   "sidebar.textField": "Text Field",
   "sidebar.selectList": "Select List",
   "sidebar.charts": "Charts",
   "sidebar.reCharts": "Re Charts",
   "sidebar.reactChartjs2": "React Chartjs 2",
   "sidebar.icons": "Icons",
   "sidebar.themifyIcons": "Themify Icons",
   "sidebar.simpleLineIcons": "Simple Line Icons",
   "sidebar.materialIcons": "Material Icons",
   "sidebar.fontAwesome": "Font Awesome",
   "sidebar.tables": "Tables",
   "sidebar.basic": "Basic",
   "sidebar.dataTable": "Data Table",
   "sidebar.responsive": "Responsive",
   "sidebar.reactTable": "React Table",
   "sidebar.maps": "Maps",
   "sidebar.googleMaps": "Google Maps",
   "sidebar.leafletMaps": "Leaflet Maps",
   "sidebar.inbox": "Inbox",
   "sidebar.users": "Users",
   "sidebar.userProfile1": "User Profile 1",
   "sidebar.userProfile2": "User Profile 2",
   "sidebar.userManagement": "User Management",
   "sidebar.userProfile": "User Profile",
   "sidebar.userList": "User List",
   "sidebar.calendar": "Calendar",
   "sidebar.cultures": "Cultures",
   "sidebar.dnd": "Dnd",
   "sidebar.selectable": "Selectable",
   "sidebar.customRendering": "Custom Rendering",
   "sidebar.chat": "Chat",
   "sidebar.toDo": "ToDo",
   "sidebar.editor": "Editor",
   "sidebar.wysiwygEditor": "WYSIWYG Editor",
   "sidebar.quillEditor": "Quill Editor",
   "sidebar.reactAce": "React Ace",
   "sidebar.dragAndDrop": "Drag And Drop",
   "sidebar.reactDragula": "React Dragula",
   "sidebar.reactDnd": "React Dnd",
   "sidebar.blogManagement": "Blog Management",
   "sidebar.ecommerce": "Ecommerce",
   "sidebar.shopList": "Shop List",
   "sidebar.shopGrid": "Shop Grid",
   "sidebar.invoice": "Invoice",
   "sidebar.multilevel": "Multilevel",
   "sidebar.sublevel": "Sublevel",
   "sidebar.crypto": "Crypto",
   "sidebar.marketCap": "Market cap",
   "sidebar.wallet": "wallet",
   "sidebar.configuration": "Configuration",
   "sidebar.coverage": "Coverage",
   "sidebar.catalog": "Catalog",
   "sidebar.trade": "trade",
   "sidebar.crm": "CRM",
   "sidebar.branch": "Branch",
   "sidebar.create": "Create",
   "sidebar.product": "Product",
   "sidebar.productCategory": "Product category",
   "sidebar.products": "Products",
   "sidebar.category": "Category",
   "sidebar.productType": "Product Type",
   "sidebar.catalogProducts": "Catalogue Products",
   "sidebar.catalogSales": "Catalogue Sales",
   "sidebar.catalogDistribution": "Catalogue Distribution",
   "widgets.active": "Active",
   "widgets.totalEarns": "Total Earns",
   "widgets.emailsStatistics": "Emails Statistics",
   "widgets.totalRevenue": "Total Revenue",
   "widgets.onlineVistors": "Online Vistors",
   "widgets.trafficSources": "Traffic Sources",
   "widgets.RecentOrders": "Recent Orders",
   "widgets.topSellings": "Top Sellings",
   "widgets.productReports": "Product Reports",
   "widgets.productStats": "Product Stats",
   "widgets.ComposeEmail": "Compose Email",
   "widgets.employeePayroll": "Employee Payroll",
   "widgets.visitors": "Visitors",
   "widgets.orders": "Orders",
   "widgets.orderStatus": "Order Status",
   "widgets.totalSales": "Total Sales",
   "widgets.netProfit": "Net Profit",
   "widgets.overallTrafficStatus": "Overall Traffic Status",
   "widgets.tax": "Tax",
   "widgets.expenses": "Expenses",
   "widgets.currentTime": "Current Time",
   "widgets.currentDate": "Current Date",
   "widgets.todayOrders": "Today Orders",
   "widgets.toDoList": "To Do Lists",
   "widgets.discoverPeople": "Discover People",
   "widgets.commments": "Comments",
   "widgets.newCustomers": "New Customers",
   "widgets.recentNotifications": "Recent Notifications",
   "widgets.appNotifications": "App Notifications",
   "widgets.ratings": "Ratings",
   "widgets.newEmails": "New Emails",
   "widgets.siteVisitors": "Site Visitors",
   "widgets.socialCompanines": "Social Companines",
   "widgets.recentActivities": "Recent Activities",
   "widgets.recentOrders": "Recent Orders",
   "widgets.gallery": "Gallery",
   "widgets.pricing": "Pricing",
   "widgets.enterpriseEdition": "Enterprise Edition",
   "widgets.personalEdition": "Personal Edition",
   "widgets.teamEdition": "Team Edition",
   "widgets.standard": "Standard",
   "widgets.advanced": "Advanced",
   "widgets.master": "Master",
   "widgets.Mega": "Mega",
   "widgets.logIn": "Log In",
   "widgets.signUp": "Sign Up",
   "widgets.lockScreen": "Lock Screen",
   "widgets.alertsWithLink": "Alerts With Link",
   "widgets.additionalContent": "Additional Content",
   "widgets.alertDismiss": "Alert Dismiss",
   "widgets.uncontrolledDisableAlerts": "Uncontrolled Disable Alerts",
   "widgets.contexualAlerts": "Contexual Alerts",
   "widgets.alertsWithIcons": "Alerts With Icons",
   "widgets.Simple App Bars": "Simple App Bars",
   "widgets.appBarsWithButtons": "App Bars With Buttons",
   "widgets.imageAvatars": "Image Avatars",
   "widgets.lettersAvatars": "Letters Avatars",
   "widgets.iconsAvatars": "Icons Avatars",
   "widgets.flatButtons": "Flat Buttons",
   "widgets.raisedButton": "Raised Button",
   "widgets.buttonWithIconAndLabel": "Button With Icon And Label",
   "widgets.floatingActionButtons": "Floating Action Buttons",
   "widgets.iconButton": "Icon Button",
   "widgets.socialMediaButton": "Social Media Button",
   "widgets.reactButton": "React Button",
   "widgets.buttonOutline": "Button Outline",
   "widgets.buttonSize": "Button Size",
   "widgets.buttonState": "Button State",
   "widgets.buttonNavigationWithNoLabel": "button Navigation With No Label",
   "widgets.buttonNavigation": "Button Navigation",
   "widgets.iconNavigation": "Icon Navigation",
   "widgets.badgeWithHeadings": "Badge With Headings",
   "widgets.contexualVariations": "Contexual Variations",
   "widgets.badgeLinks": "Badge Links",
   "widgets.materialBadge": "Material Badge",
   "widgets.simpleCards": "Simple Cards",
   "widgets.backgroundVarient": "Background Varient",
   "widgets.cardOutline": "Card Outline",
   "widgets.overlayCard": "Overlay Card",
   "widgets.cardGroup": "Card Group",
   "widgets.cardTitle": "Card Title",
   "widgets.speacialTitleTreatment": "Speacial Title Treatment",
   "widgets.chipWithClickEvent": "Chip With Click Event",
   "widgets.chipArray": "Chip Array",
   "widgets.dialogs": "Dialogs",
   "widgets.listDividers": "List Dividers",
   "widgets.insetDividers": "Inset Dividers",
   "widgets.temporaryDrawers": "Temporary Drawers",
   "widgets.permanentDrawers": "Permanent Drawers",
   "widgets.simpleExpansionPanel": "Simple Expansion Panel",
   "widgets.controlledAccordion": "Controlled Accordion",
   "widgets.secondaryHeadingAndColumns": "Secondary Heading And Columns",
   "widgets.imageOnlyGridLists": "Image Only Grid Lists",
   "widgets.advancedGridLists": "Advanced Grid Lists",
   "widgets.singleLineGridLists": "Single Line Grid Lists",
   "widgets.simpleLists": "Simple Lists",
   "widgets.folderLists": "Folder Lists",
   "widgets.listItemWithImage": "List Item With Image",
   "widgets.switchLists": "Switch Lists",
   "widgets.insetLists": "Inset Lists",
   "widgets.nestedLists": "Nested Lists",
   "widgets.checkboxListControl": "Checkbox List Control",
   "widgets.pinedSubHeader": "Pined Sub Header",
   "widgets.InteractiveLists": "Interactive Lists",
   "widgets.simpleMenus": "Simple Menus",
   "widgets.selectedMenu": "Selected Menu",
   "widgets.maxHeightMenu": "Max Height Menu",
   "widgets.changeTransition": "Change Transition",
   "widgets.paper": "Paper",
   "widgets.anchorPlayGround": "Anchor Play Ground",
   "widgets.tooltip": "ToolTip",
   "widgets.positionedToolTips": "Positioned Snackbar",
   "widgets.circularProgressBottomStart": "Circular Progress Bottom Start",
   "widgets.interactiveIntegration": "Interactive Integration",
   "widgets.determinate": "Determinate",
   "widgets.linearProgressLineBar": "Linear Progress Line Bar",
   "widgets.indeterminate": "Indeterminate",
   "widgets.buffer": "Buffer",
   "widgets.query": "Query",
   "widgets.transitionControlDirection": "Transition Control Direction",
   "widgets.simpleSnackbar": "Simple Snackbar",
   "widgets.positionedSnackbar": "Positioned Snackbar",
   "widgets.contexualColoredSnackbars": "Contexual Colored Snackbars",
   "widgets.simpleCheckbox": "Simple Checkbox",
   "widgets.interminateSelection": "Interminate Selection",
   "widgets.disabledCheckbox": "Disabled Checkbox",
   "widgets.customColorCheckbox": "Custom Color Checkbox",
   "widgets.VerticalStyleCheckbox": "Vertical Style Checkbox",
   "widgets.horizontalStyleCheckbox": "Horizontal Style Checkbox",
   "widgets.radioButtons": "Radio Buttons",
   "widgets.disabledRadio": "Disabled Radio",
   "widgets.withError": "With Error",
   "widgets.switches": "Swiches",
   "widgets.dateAndTimePicker": "Date And Time Picker",
   "widgets.defaultPicker": "Default Picker",
   "widgets.timePicker": "Time Picker",
   "widgets.weekPicker": "Week Picker",
   "widgets.defaultDatePicker": "Default Date Picker",
   "widgets.customPicker": "Custom Picker",
   "widgets.tabs": "Tabs",
   "widgets.fixedTabs": "Fixed Tabs",
   "widgets.basicTab": "Basic Tab",
   "widgets.wrappedLabels": "Wrapped Labels",
   "widgets.centeredLabels": "Centered Labels",
   "widgets.forcedScrolledButtons": "Forced Scrolled Buttons",
   "widgets.iconsTabs": "Icons Tabs",
   "widgets.withDisableTabs": "With Disable Tabs",
   "widgets.iconWithLabel": "Icon With Label",
   "widgets.stepper": "Stepper",
   "widgets.horizontalLinear": "Horizontal Linear",
   "widgets.horizontalNonLinear": "Horizontal Non Linear",
   "widgets.horizontalLinerAlternativeLabel": "Horizontal Liner Alternative Label",
   "widgets.horizontalNonLinerAlternativeLabel": "Horizontal Non Liner Alternative Label",
   "widgets.verticalStepper": "Vertical Stepper",
   "widgets.descriptionAlert": "Description Alert",
   "widgets.customIconAlert": "Custom Icon Alert",
   "widgets.withHtmlAlert": "With Html Alert",
   "widgets.promptAlert": "Prompt Alert",
   "widgets.passwordPromptAlert": "Password Prompt Alert",
   "widgets.customStyleAlert": "Custom Style Alert",
   "widgets.autoComplete": "Auto Complete",
   "widgets.reactSelect": "React Select",
   "widgets.downshiftAutoComplete": "Downshift Auto Complete",
   "widgets.reactAutoSuggests": "React Auto Suggests",
   "widgets.aboutUs": "About Us",
   "widgets.ourVission": "Our Vission",
   "widgets.ourMissions": "Our Missions",
   "widgets.ourMotivation": "Our Motivation",
   "widgets.defualtReactForm": "Defualt React Form",
   "widgets.url": "Url",
   "widgets.textArea": "Text Area",
   "widgets.file": "File",
   "widgets.formGrid": "Form Grid",
   "widgets.inlineForm": "Inline Form",
   "widgets.inputSizing": "Input Sizing",
   "widgets.inputGridSizing": "Input Grid Sizing",
   "widgets.hiddenLabels": "Hidden Labels",
   "widgets.formValidation": "Form Validation",
   "widgets.number": "Number",
   "widgets.date": "Date",
   "widgets.time": "Time",
   "widgets.color": "Color",
   "widgets.search": "Search",
   "widgets.selectMultiple": "Select Multiple",
   "widgets.inputWithSuccess": "Input With Success",
   "widgets.inputWithDanger": "Input With Danger",
   "widgets.simpleTextField": "Simple Text Field",
   "widgets.componet": "Components",
   "widgets.layouts": "Layouts",
   "widgets.inputAdorements": "Input Adorements",
   "widgets.formattedInputs": "Formatted Inputs",
   "widgets.simpleSelect": "Simple Select",
   "widgets.nativeSelect": "Native Select",
   "widgets.MutltiSelectList": "Mutlti Select List",
   "widgets.lineChart": "Line Chart",
   "widgets.barChart": "Bar Chart",
   "widgets.stackedBarChart": "Stacked Bar Chart",
   "widgets.lineBarAreaChart": "Line Bar Area Chart",
   "widgets.areaChart": "Area Chart",
   "widgets.stackedAreaChart": "Stacked Area Chart",
   "widgets.verticalChart": "Vertical Chart",
   "widgets.radarChart": "Radar Chart",
   "widgets.doughnut": "Doughnut",
   "widgets.polarChart": "Polar Chart",
   "widgets.pieChart": "Pie Chart",
   "widgets.bubbleChart": "Bubble Chart",
   "widgets.horizontalBar": "Horizontal Bar",
   "widgets.basicTable": "Basic Table",
   "widgets.contexualColoredTable": "Contexual Colored Table",
   "widgets.dataTable": "Data Table",
   "widgets.employeeList": "Employee List",
   "widgets.responsiveTable": "Responsive Table",
   "widgets.responsiveFlipTable": "Responsive Flip Table",
   "widgets.reactGridControlledStateMode": "React Grid Controlled State Mode",
   "widgets.productsReports": "Products Reports",
   "widgets.taskList": "Task List",
   "widgets.basicCalender": "Basic Calender",
   "widgets.culturesCalender": "Cultures Calender",
   "widgets.dragAndDropCalender": "Drag And Drop Calender",
   "widgets.selectableCalender": "Selectable Calender",
   "widgets.customRendering": "Custom Rendering",
   "widgets.customCalender": "Custom Calender",
   "widgets.searchMailList": "Search Mail List",
   "components.buyNow": "Buy Now",
   "compenets.choose": "Choose",
   "compenets.username": "Username",
   "compenets.passwords": "Passwords",
   "widgets.forgetPassword": "Forget Password",
   "compenets.signIn": "Sign In",
   "compenets.dontHaveAccountSignUp": "Dont Have Account SignUp",
   "compenets.enterUserName": "Enter User Name",
   "compenets.enterEmailAddress": "Enter Email Address",
   "compenets.confirmPasswords": "Confirm Passwords",
   "components.alreadyHavingAccountSignIn": "Already Having Account Sign In",
   "components.enterYourPassword": "Enter Your Password",
   "components.unlock": "Unlock",
   "components.enterPasswords": "Enter Passwords",
   "components.resestPassword": "Resest Password",
   "components.pageNotfound": "Page not Found",
   "components.goToHomePage": "Go To Home Page",
   "components.sorryServerGoesWrong": "Sorry Server Goes Wrong",
   "components.persistentDrawer": "Persistent Drawer",
   "components.withHtml": "With Html",
   "components.prompt": "Prompt",
   "components.withDescription": "With Description",
   "components.success": "Success",
   "components.passwordPrompt": "Password Prompt",
   "components.warning": "Warning",
   "components.customIcon": "Custom Icon",
   "components.customStyle": "Custom Style",
   "components.basic": "Basic",
   "components.submit": "Submit",
   "components.compose": "Compose",
   "components.sendMessage": "Send Message",
   "components.addNewTasks": "Add New Tasks",
   "components.addToCart": "Add To Cart",
   "components.payNow": "Pay Now",
   "components.print": "Print",
   "components.cart": "Cart",
   "components.viewCart": "View Cart",
   "components.checkout": "Checkout",
   "widgets.QuickLinks": "Quick Links",
   "widgets.upgrade": "upgrade",
   "widgets.app": "App",
   "widgets.addNew": "Add New",
   "widgets.orderDate": "Order Date",
   "widgets.status": "Status",
   "widgets.trackingNumber": "Tracking Number",
   "widgets.action": "Action",
   "widgets.designation": "Designation",
   "widgets.subject": "subject",
   "widgets.send": "Send",
   "widgets.saveAsDrafts": "Save As Drafts",
   "widgets.onlineSources": "Online Sources",
   "widgets.lastMonth": "Last Month",
   "widgets.widgets": "Widgets",
   "widgets.listing": "Listing",
   "widgets.paid": "Paid",
   "widgets.refunded": "Refunded",
   "widgets.done": "Done",
   "widgets.pending": "Pending",
   "widgets.accepted": "Accepted",
   "widgets.cancelled": "Cancelled",
   "widgets.approve": "Approve",
   "widgets.approved": "Approved",
   "widgets.following": "Following",
   "widgets.follow": "follow",
   "widgets.graphs&Charts": "Graphs & Charts",
   "widgets.open": "Open",
   "widgets.bounced": "Bounced",
   "widgets.spam": "Spam",
   "widgets.unset": "Unset",
   "widgets.bandwidthUse": "Bandwidth Use",
   "widgets.dataUse": "Data Use",
   "widgets.unsubscribe": "Unsubscribe",
   "widgets.profile": "Profile",
   "widgets.messages": "Messages",
   "widgets.support": "Support",
   "widgets.faq(s)": "Faq(s)",
   "widgets.upgradePlains": "Upgrade Plains",
   "widgets.logOut": "Log Out",
   "widgets.mail": "Mail",
   "widgets.adminTheme": "Admin Theme",
   "widgets.wordpressTheme": "Wordpress Theme",
   "widgets.addToCart": "Add To Cart",
   "widgets.plan": "Plan",
   "widgets.basic": "Basic",
   "widgets.pro": "Pro",
   "widgets.startToBasic": "Start To Basic",
   "widgets.upgradeToPro": "Upgrade To Pro",
   "widgets.upgradeToAdvance": "Upgrade To Advance",
   "widgets.comparePlans": "Compare Plans",
   "widgets.free": "Free",
   "widgets.frequentlyAskedQuestions": "Frequently Asked Questions",
   "widgets.searchIdeas": "Search Ideas",
   "widgets.startDate": "Start Date",
   "widgets.endDate": "End Date",
   "widgets.category": "Category",
   "widgets.apply": "Apply",
   "widgets.yesterday": "Yesterday",
   "widgets.totalOrders": "Total Orders",
   "widgets.totalVisitors": "Total Visitors",
   "widgets.typeYourQuestions": "Type Your Questions",
   "widgets.username": "Username",
   "widgets.password": "Password",
   "widgets.signIn": "Sign In",
   "widgets.enterYourPassword": "Enter Your Password",
   "widgets.alreadyHavingAccountLogin": "Already Having Account Login",
   "widgets.composeMail": "Compose Mail",
   "widgets.issue": "Issue",
   "widgets.recentChat": "Recent Chat",
   "widgets.previousChat": "Previous Chat",
   "widgets.all": "All",
   "widgets.filters": "Filters",
   "widgets.deleted": "Deleted",
   "widgets.starred": "Starred",
   "widgets.frontend": "Frontend",
   "widgets.backend": "Backend",
   "widgets.api": "Api",
   "widgets.simpleAppBar": "Simple App Bar",
   "widgets.recents": "Recents",
   "widgets.cardLink": "Card Link",
   "widgets.anotherLink": "Another Link",
   "widgets.cardSubtitle": "card Subtitle",
   "widgets.confirmationDialogs": "Confirmation Dialogs",
   "widgets.deletableChip": "Deletable Chip",
   "widgets.customDeleteIconChip": "Custom Delete Icon Chip",
   "widgets.openAlertDialog": "Open Alert Dialog",
   "widgets.openResponsiveDialog": "Open Responsive Dialog",
   "widgets.openSimpleDialog": "Open Simple Dialog",
   "widgets.openFormDialog": "Open Form Dialog",
   "widgets.follower": "Follower",
   "widgets.important": "Important",
   "widgets.private": "Private",
   "widgets.openLeft": "Open Left",
   "widgets.openRight": "Open Right",
   "widgets.openTop": "Open Top",
   "widgets.openBottom": "Open Bottom",
   "widgets.selectTripDestination": "Select Trip Destination",
   "widgets.pinnedSubheaderList": "Pinned Subheader List",
   "widgets.singleLineItem": "Single Line Item",
   "widgets.optionA": "Option A",
   "widgets.optionB": "Option B",
   "widgets.optionC": "Option C",
   "widgets.optionM": "Option M",
   "widgets.optionN": "Option N",
   "widgets.optionO": "Option O",
   "widgets.customColor": "Custom Color",
   "widgets.centeredTabs": "Centered Tabs",
   "widgets.multipleTabs": "Multiple Tabs",
   "widgets.preventScrolledButtons": "Prevent Scrolled Buttons",
   "widgets.browse": "Browse",
   "widgets.formValidate": "Form Validate",
   "widgets.code": "Code",
   "widgets.company": "Company",
   "widgets.price": "Price",
   "widgets.change": "Change",
   "widgets.high": "High",
   "widgets.low": "Low",
   "widgets.volume": "Volume",
   "widgets.personalDetails": "Personal Details",
   "widgets.occupation": "Occupation",
   "widgets.companyName": "Company Name",
   "widgets.phoneNo": "Phone No",
   "widgets.updateProfile": "Update Profile",
   "widgets.reject": "Reject",
   "widgets.exportToExcel": "Export To Excel",
   "widgets.workWeek": "Work Week",
   "widgets.agenda": "Agenda",
   "widgets.conference": "Conference",
   "widgets.multilevel": "Multilevel",
   "widgets.dailySales": "Daily Sales",
   "widgets.today": "Today",
   "widgets.campaignPerformance": "Campaign Performance",
   "widgets.supportRequest": "Support Request",
   "widgets.usersList": "Users List ",
   "widgets.lastWeek": "Last Week",
   "themeOptions.sidebarOverlay": "Sidebar Overlay",
   "themeOptions.sidebarBackgroundImages": "Sidebar Background Images",
   "themeOptions.appSettings": "App Settings",
   "themeOptions.sidebarImage": "Sidebar Image",
   "themeOptions.miniSidebar": "Mini Sidebar",
   "themeOptions.boxLayout": "Box Layout",
   "themeOptions.rtlLayout": "Rtl Layout",
   "themeOptions.darkMode": "Dark Mode",
   "themeOptions.sidebarLight": "Light",
   "themeOptions.sidebarDark": "Dark",
   "button.cancel": "Cancel",
   "button.add": "Add",
   "button.add.manually": "Add Manually",
   "button.add.excel": "Add from Excel",
   "button.edit": "Edit",
   "button.finish": "Finish",
   "button.update": "Update",
   "button.reply": "Reply",
   "button.delete": "Delete",
   "button.yes": "Yes",
   "button.no": "No",
   "button.viewAll": "View All",
   "button.like": "Like",
   "button.assignNow": "Assign Now",
   "button.declare": "Declare",
   "button.reset": "Reset",
   "button.start": "Start",
   "button.activate": "Activate",
   "button.seeInsights": "See Insights",
   "button.unassign": "Unassign",
   "button.credit": "Credit",
   "button.credit.account": "Credit Account",
   "button.suspend": "Suspend",
   "button.restore": "Restore",
   "button.pay": "Pay",
   "sidebar.dateTimePicker": "Date & Time Picker",
   "components.summary": "Summary",
   "hint.whatAreYouLookingFor": "What are You Looking For",
   "components.yesterday": "Yesterday",
   "components.last7Days": "Last 7 Days",
   "components.last1Month": "Last 1 Month",
   "components.last6Month": "Last 6 Month",
   "components.spaceUsed": "Space Used",
   "components.followers": "Follower",
   "components.trending": "Trending",
   "components.paid": "Paid",
   "components.refunded": "Refunded",
   "components.done": "Done",
   "components.pending": "Pending",
   "components.cancelled": "Cancelled",
   "components.approve": "Approve",
   "components.week": "Week",
   "components.month": "Month",
   "components.year": "Year",
   "components.today": "Today",
   "components.popularity": "Popularity",
   "components.email": "Email",
   "components.drafts": "Drafts",
   "components.sent": "Sent",
   "components.trash": "Trash",
   "components.all": "All",
   "components.do": "Do",
   "components.title": "Title",
   "components.projectName": "Project Name",
   "components.companyName": "Company Name",
   "components.openAlert": "Open Alert",
   "components.slideInAlertDialog": "Slide In Alert Dialog",
   "components.openFullScreenDialog": "Open Full Screen Dialogs",
   "components.basicChip": "Basic Chip",
   "components.clickableChip": "Clickable Chip",
   "components.left": "Left",
   "components.right": "Right",
   "components.expansionPanel1": "Expansion Panel 1",
   "components.expansionPanel2": "Expansion Panel 2",
   "components.generalSetting": "General Setting",
   "components.advancedSettings": "Advanced Settings",
   "components.name": "Name",
   "components.firstName": "First Name",
   "components.lastName": "Last Name",
   "components.occupation": "Occupation",
   "components.phoneNo": "Phone No",
   "components.address": "Address",
   "components.city": "City",
   "components.state": "State",
   "components.zipCode": "Zip Code",
   "components.social Connection": "Social Connection",
   "widgets.buyMore": "Buy More",
   "widgets.trafficChannel": "Traffic Channel",
   "widgets.stockExchange": "Stock Exchange",
   "widgets.tweets": "Tweets",
   "widgets.ourLocations": "Our Locations",
   "widgets.sales": "Sales",
   "widgets.to": "To",
   "widgets.shipTo": "Ship To",
   "widgets.description": "Description",
   "widgets.unitPrice": "Unit Price",
   "widgets.total": "Total",
   "widgets.note": "Note",
   "widgets.chipWithAvatar": "Chip With Avatar",
   "widgets.chipWithTextAvatar": "Chip With Text Avatar",
   "widgets.chipWithIconAvatar": "Chip With Icon Avatar",
   "widgets.customClickableChip": "Custom Clickable Chip",
   "widgets.outlineChip": "Outline Chip",
   "widgets.disableChip": "Disable Chip",
   "widgets.alertDialog": "Alert Dialog",
   "widgets.animatedSlideDialogs": "Animated Slide Dialogs",
   "widgets.fullScreenDialogs": "Full Screen Dialogs",
   "widgets.formDialogs": "Form Dialogs",
   "widgets.simpleDialogs": "Simple Dialogs",
   "widgets.responsiveFullScreen": "Responsive Full Screen",
   "widgets.primary": "Primary",
   "widgets.social": "Social",
   "widgets.user": "User",
   "widgets.admin": "Admin",
   "widgets.permanentdrawer": "Permanent Drawer",
   "widgets.persistentdrawer": "Persistent Drawer",
   "widgets.swiches": "Swiches",
   "widgets.horizontalLinearAlternativeLabel": "Horizontal Linear Alternative Label",
   "widgets.horizontalNonLinearAlternativeLabel": "Horizontal Non Linear Alternative Label",
   "widgets.notifications": "Notifications",
   "widgets.basicAlert": "Basic Alert",
   "widgets.successAlert": "Success Alert",
   "widgets.warningAlert": "Warning Alert",
   "widgets.reactAutoSuggest": "React Auto Suggest",
   "widgets.components": "Components",
   "widgets.inputAdornments": "Input Adorements",
   "widgets.multiSelectList": "Multi Select List",
   "widgets.contextualColoredTable": "Contexual Colored Table",
   "widgets.updateYourEmailAddress": "Update Your Email Address",
   "widgets.selectADefaultAddress": "Select A Default Address",
   "widgets.activity": "Activity",
   "widgets.basicCalendar": "Basic Calendar",
   "widgets.culturesCalendar": "Cultures Calendar",
   "widgets.dragAndDropCalendar": "Drag And Drop Calendar",
   "widgets.quillEditor": "Quill Editor",
   "widgets.reactDND": "React DND",
   "widgets.dragula": "Dragula",
   "button.acceptTerms": "Accept Terms",
   "button.reject": "Reject",
   "button.addNew": "Add New",
   "button.goToCampaign": "Go To Campaign",
   "button.viewProfile": "View Profile",
   "button.sendMessage": "Send Message",
   "button.saveNow": "Save Now",
   "button.pen": "Pen",
   "button.search": "Search",
   "button.downloadPdfReport": "Download Pdf Report",
   "button.primary": "Primary",
   "button.secondary": "Secondary",
   "button.danger": "Danger",
   "button.info": "Info",
   "button.success": "Success",
   "button.warning": "Warning",
   "button.link": "Link",
   "button.smallButton": "Small Button",
   "button.largeButton": "Large Button",
   "button.blockLevelButton": "Block Level Button",
   "button.primaryButton": "Primary Button",
   "button.button": "Button",
   "button.save": "Save",
   "button.openMenu": "Open Menu",
   "button.openWithFadeTransition": "Open With Fade Transition",
   "button.openPopover": "Open Popover",
   "button.accept": "Accept",
   "button.refuse": "Refuse",
   "button.denied": "Denied",
   "button.remove": "Remove",
   "button.click": "Click",
   "button.complete": "Complete",
   "button.submit": "Submit",
   "button.back": "Back",
   "button.next": "Next",
   "button.previous": "Previous",
   "button.completeStep": "Complete Step",
   "button.error": "Error",
   "button.writeNewMessage": "Write New Message",
   "button.saveChanges": "Save Changes",
   "button.addNewUser": "Add New User",
   "button.discard": "Discard",
   "button.more": "More",
   "button.choose": "Choose",
   "hint.searchMailList": "Search Mail List",
   "widgets.AcceptorrRejectWithin": "Accept or reject within",
   "widgets.quoteOfTheDay": "Quote Of The Day",
   "widgets.updated10Minago": "Updated 10 min ago",
   "widgets.personalSchedule": "Personal Schedule",
   "widgets.activeUsers": "Active Users",
   "widgets.totalRequest": "Total Request",
   "widgets.new": "New",
   "widgets.ShareWithFriends": "Share with friends!",
   "widgets.helpToShareText": "Help us spread the world by sharing our website with your friends and followers on social media!",
   "widgets.thisWeek": "This Week",
   "widgets.howWouldYouRateUs": "How would you rate us?",
   "widgets.booking": "Booking",
   "widgets.confirmed": "Confirmed",
   "widgets.monthly": "Monthly",
   "widgets.weekly": "Weekly",
   "widgets.target": "Target",
   "widgets.totalActiveUsers": "Total Active Users",
   "sidebar.user": "User",
   "sidebar.miscellaneous": "Miscellaneous",
   "sidebar.promo": "Promo",
   "themeOptions.themeColor": "Theme Color",
   "module.inbox": "Inbox",
   "module.drafts": "Drafts",
   "module.sent": "Sent",
   "module.trash": "Trash",
   "module.spam": "Spam",
   "module.frontend": "Frontend",
   "module.backend": "Backend",
   "module.api": "Api",
   "module.issue": "Issue",
   "components.emailPrefrences": "Email Prefrences",
   "components.myProfile": "My Profile",
   "sidebar.gettingStarted": "Getting Started",
   "widgets.deadline": "Deadline",
   "widgets.team": "Team",
   "widgets.projectManagement": "Project Management",
   "widgets.latestPost": "Latest Post",
   "widgets.projectTaskManagement": "Project Task Management",
   "widgets.selectProject": "Select Project",
   "widgets.activityBoard": "Activity Board",
   "widgets.checklist": "Checklist",
   "sidebar.shop": "Shop",
   "sidebar.cart": "Cart",
   "sidebar.checkout": "Checkout",
   "components.product": "Product",
   "components.quantity": "Quantity",
   "components.totalPrice": "Total Price",
   "components.removeProduct": "Remove Product",
   "components.mobileNumber": "Mobile Number",
   "components.address2Optional": "Address 2 (Optional)",
   "components.country": "Country",
   "components.zip": "Zip",
   "components.saveContinue": "Save & Continue",
   "components.placeOrder": "Place Order",
   "components.payment": "Payment",
   "components.billingAddress": "Billing Address",
   "components.ShippingAddressText": "Shipping address is the same as billing address.",
   "components.CartEmptyText": "Your Shopping Cart Is Empty!",
   "components.NoItemFound": "No Item Found",
   "components.goToShop": "Go To Shop",
   "components.cardNumber": "Card Number",
   "components.expiryDate": "Expiry Date",
   "components.cvv": "CVV",
   "components.nameOnCard": "Name On Card",
   "components.confirmPayment": "Confirm Payment",
   "sidebar.saas": "SAAS",
   "sidebar.multiLevel": "MultiLevel",
   "sidebar.level1": "Level 1",
   "sidebar.level2": "Level 2",
   "sidebar.boxed": "Boxed",
   "sidebar.extensions": "Extensions",
   "sidebar.imageCropper": "Image Cropper",
   "sidebar.videoPlayer": "Video Player",
   "sidebar.dropzone": "Dropzone",
   "widgets.baseConfig": "Base Config",
   "widgets.customControlBar": "Custom Control Bar",
   "widgets.withDownloadButton": "With Download Button",
   "widgets.httpLiveStreaming": "HTTP Live Streaming",
   "widgets.keyboardShortcuts": "Keyboard Shortcuts",
   "button.useDefaultImage": "Use Default Image",
   "button.cropImage": "Crop Image",
   "widgets.preview": "Preview",
   "widgets.croppedImage": "Cropped Image",
   "widgets.transactionList": "Transaction List",
   "widgets.transferReport": " Transfer Report",
   "widgets.expenseCategory": "Expense Category",
   "widgets.upcomingEvents": "Upcoming events",
   "widgets.OngoingProjects": "Ongoing Projects",
   "widgets.ProjectStatus": "Project Status",
   "widgets.LiveChatSupport": "Live Chat Support",
   "sidebar.projects": "projects",
   "sidebar.projectDetail": "project Detail",
   "sidebar.clients": "clients",
   "sidebar.reports": "reports",
   "list.noItemToDisplay": "No items to display",
   "list.noThingToDisplay": "No {thing} to display",
   "list.sortBy": "Sort by",
   "general.none": "None",
   "general.typeof": "{thing} Type",
   "general.parentProfile": "Parent profile",
   "general.validityPeriod": "Validity period",
   "general.validityPeriodInMonth": "Validity period in months",
   "general.members": "Members",
   "general.createdAt": "Created at",
   "general.welcome.komunityoo": "Welcome on Komunityoo",
   "general.details": "Details",
   "general.sent": "Sent",
   "general.received": "Received",
   "general.yesWant": "Yes I want",
   "general.noWant": "No I don't want",
   "general.confirm": "Confirm",
   "general.location": "Location",
   "general.finance": "Finance",
   "general.date.range": "From {from} to {to}",
   "general.date.range.from": "From {date}",
   "general.date.range.to": "To {date}",
   "general.late": "Late",
   "general.absence": "Absence",
   "general.history": "History",
   "general.reference": "Reference",
   "general.reason": "Reason",
   "general.type": "Type",
   "general.amount": "Amount",
   "general.balance": "Balance",
   "general.balance.before": "Balance before",
   "general.balance.after": "Balance after",
   "general.transaction.id": "Transaction id",
   "general.transaction.id.short": "Trans. ID",
   "general.request.deletion": "Request a deletion",
   "general.request.activation": "Request an activation",
   "general.being.deleted": "Being deleted",
   "general.being.activated": "Being activated",
   "general.overview": "Overview",
   "general.income": "Income",
   "general.outcome": "Outcome",
   "general.incomes": "Incomes",
   "general.outcomes": "Outcomes",
   "general.variance": "Variance",
   "general.range.from": "From",
   "general.range.to": "To",
   "edit.profile": "Edit profile",
   "gender": "Gender",
   "gender.male": "Male",
   "gender.female": "Female",
   "gender.mentor": "Mentor",
   "date.day": "Day",
   "date.days": "Days",
   "date.week": "Week",
   "date.weeks": "Weeks",
   "date.month": "Month",
   "date.months": "Months",
   "date.year": "Year",
   "date.years": "Years",
   "date.createdAt": "Created At",
   "language.english": "English",
   "language.french": "French",
   "feedback.sent": "Feedback Sent",
   "feedback.received": "Feedback Received",
   "feedback.sendNewMessage": "Send new message",
   "feedback.found": "{count, plural, =0 {No feedback found} one {1 feedback found} other { {count} feedback found}}",
   "feedback.created.successText": "Your message has been sent successfully",

   "requests.found": "{count, plural, =0 {No request found} one {1 request found} other { {count} request found}}",

   "catalog": "Catalog",
   "catalog.type": "Catalog Type",
   "catalog.create.text": "Create Catalog",
   "catalog.create.successText": "Catalog created successfully",
   "catalog.found": "{count} catalog(s) found",
   "activeCatalog.alert.title": "Activation Confirm",
   "activeCatalog.alert.text": "Do you really want to active this catalog ? This will disable the others",
   "activeCatalog.alert.successText": "Catalog activated successfully",

   "plans": "{count, plural, one {Plan} other {Plans}}",
   "plans.plural": "Plans",
   "plans.create": "Create Plan",
   "plans.found": "{count, plural, =0 {No plan found} one {1 plan found} other { {count} plans found}}",
   "plans.name": "Plan name",
   "plans.create.success": "Plan created successfully",
   "plans.status.change.success": "Successfully changed Status",
   "plans.status.change.error": "Error when changing status",
   "plans.view.example": "View example of plan",
   "plans.view.choose": "Choose the plan that works for you",
   "features.add": "Add new feature",
   "features.no": "No features found",
   "features.title": "Try edit and watch changes",
   // "features.user": "{count, plural, one {For {count} user} other {For {count} users}",
   "features.member": "{count, plural, one {{count} member} other {{count} members }}",
   "features.months": "{count, plural, one {{count} month} other {{count} months }}",
   "register.sampleCommunity.step1": "Information about community",
   "register.sampleCommunity.step2": "Information about manager",
   "register.sampleCommunity.step3": "Choose your plans",
   "register.sampleCommunity.not.available": "Registration is not available for now",
   "register.sampleCommunity.plan.not.selected": "Please select a plan before continue",
   "register.sampleCommunity.created.text": "Create your community",
   "register.sampleCommunity.created.success": "Your request has been sent successfully.",
   "register.sampleCommunity.updated.success": "Your update has been done successfully.",
   "register.sampleCommunity.created.thankYou.title": "Your request has been successfully sent. We have sent you an email about the next step. Please check it.",
   "register.sampleCommunity.activated.success": "The community has been activated successfully.",


   "communities.create": "Create community",
   "communities": "Communities",
   "pendingCommunity": "Pending community",
   "pendingCommunities": "Pending communities",
   "pendingCommunities.found": "{count, plural, =0 {No pending community found} one {1 pending community found} other { {count} pending communities found}}",
   "pendingCommunities.validate": "Validate Community",
   "pendingCommunities.editBtn": "Edit Community",

   "permission": "Permission",
   "permissions": "Permissions",
   "permissions.select": "Select permissions",
   "permission.category.plan": "Plan",

   "roles": "{count, plural, one {Role} other {Roles}}",
   "roles.found": "{count, plural, =0 {No role found} one {1 role found} other { {count} roles found}}",
   "role": "Role",
   "roles.plural": "Roles",
   "rolesManagement": "Roles management",
   "roles.create": "Create Role",
   "roles.update": "Update Role",
   "roles.create.success": "Role created successfully",
   "roles.update.success": "Role updated successfully",
   "role.user.related": "Users assigned to this role",
   "role.user.remove": "Remove user to this role",
   "role.unassigned.successText": "Updated successfully",
   "role.user.remove.all.checked": "Update role's members",
   "role.user.remove.confirm": "Do you really want to update this role",
   "role.user.remove.none": "No users linked to this role",

   "status": "{count, plural, one {Status} other {Status}}",
   "status.plural": "Status",
   "status.create": "Create Status",
   "status.update": "Update Status",
   "status.members": "List Of Members",
   "status.create.success": "Status created successfully",
   "status.update.success": "Status updated successfully",
   "status.update.blocked.text": "Do you really want to block this user ?",
   "status.update.unblocked.text": "Do you really want to unblock this user ?",
   "status.update.blocked.success": "User blocked successfully",
   "status.update.unblocked.success": "User unblocked successfully",
   "status.found": "{count, plural, =0 {No status found} one {1 status found} other { {count} status found}}",
   "statusManagement": "Status management",
   "status.form.canLogin": "Can have access to community ?",

   "members": "{count, plural, one {Members} other {Members}}",
   "members.add.source.community": "From community",
   "members.add.source.manually": "Manually",
   "members.remove.message": "Do you really want to remove this member from this family",
   "members.remove.pending": "Processing removing...",
   "members.remove.success": "Member removed successfully",
   "members.insurance.remove": "Remove insurance",
   "members.insurance.remove.confirm": "Do you really want to remove the insurance of this family",
   "members.ask.insurance": "Ask for insurance",
   "members.ask.pending": "Processing insurance...",
   "members.ask.pending.remove": "Processing remove insurance...",
   "members.pending.remove": "Pending remove insurance",
   "members.ask.message": "Do you really want to ask for an insurance",
   "members.ask.success": "Your request has been successfully sent",
   "members.present.title": "Present members",
   "members.present.list": "Present members list",
   "members.present.mark": "Mark as present",
   "members.present.validate.btn": "Validate this list",
   "members.present.validate.message": "Do you really want to close {count}/{total} as present members? Note that this cannot be canceled",
   "members.present.mark.successText": "List updated successfully",
   "members.present.validate.successText": "The list has been successfully closed",
   "members.absent.title": "Absent members",
   "members.absent.list": "Absent members list",
   "members.absent.mark": "Mark as absent",
   "members.absent.validate.btn": "Validate this list",
   "members.absent.validate.message": "Do you really want to close {count}/{total} as absent members? Note that this cannot be canceled",
   "members.absent.mark.successText": "List updated successfully",
   "members.absent.validate.successText": "The list has been successfully closed",
   "members.late.title": "Late members",
   "members.late.list": "Late members list",
   "members.late.mark": "Mark as late",
   "members.late.validate.btn": "Validate this list",
   "members.late.validate.message": "Do you really want to close {count}/{total} as late members? Note that this cannot be canceled",
   "members.late.mark.successText": "List updated successfully",
   "members.late.validate.successText": "The list has been successfully closed",
   "members.assigned": "{count} members assigned",
   "members.plural": "Members",
   "members.profile": "Member profile",
   "members.create": "Create Member",
   "members.update": "Update Member",
   "members.create.step.step1": "Personal information",
   "members.create.step.step2": "General Information",
   "members.create.success": "Members created successfully",
   "members.create.fromFile.text": "Upload excel file",
   "members.create.fromFile.success": "Members has been created successfully",
   "members.update.success": "Members updated successfully",
   "members.found": "{count, plural, =0 {No member found} one {1 member found} other { {count} members found}}",
   "members.employer":"Employer",
   "members.employment":"Employment",


   "events": "{count, plural, one {Event} other {Events}}",
   "events.plural": "Events",
   "events.search": "Search events",
   "events.all": "All events",
   "events.activated": "Activated events",
   "events.unactivated": "Unactivated events",
   "events.recent": "Recent events",
   "events.create": "Create Event",
   "events.update": "Update Event",
   "events.create.success": "Event created successfully",
   "events.update.success": "Event updated successfully",
   "events.activate.message": "Do you really want to activate this event ?",
   "events.activated.success": "Event activated successfully",
   "events.found": "{count, plural, =0 {No events found} one {1 events found} other { {count} events found}}",
   "events.pay.fees": "Pay fees",
   "events.fees.fees": "Fees",
   "events.fees.late": "Late Fees",
   "events.fees.absence": "Absence Fees",
   "events.pay.fees.btn.text": "Pay {amount}",
   "events.pay.fees.message": "Do you really want them to pay {amount} as fees of the event ?",
   "events.pay.fees.successText": "Members have successfully paid the fees of the event",
   "events.pay.fees.late.btn.text": "Pay {amount}",
   "events.pay.fees.late.message": "Do you really want them to pay {amount} as late fees of the event ?",
   "events.pay.fees.late.successText": "Members have successfully paid the late fees of the event",
   "events.pay.fees.absence.btn.text": "Pay {amount}",
   "events.pay.fees.absence.message": "Do you really want them to pay {amount} as absence fees of the event ?",
   "events.pay.fees.absence.successText": "Members have successfully paid the absence fees of the event",
   "event.members.action.empty": "Please select at least one member to continue",
   "events.pay.cancel.message": "Do you really want to discard the payment of {userName}",
   "events.pay.cancel.successText": "Payment cancelled successfully",
   "events.emptyWarning": "Please create an event before continuing",
   "eventPlaces.plural": "Event places",

   "eventTypes": "{count, plural, one {Event type} other {Event types}}",
   "eventTypes.sub": "Event sub types ",
   "eventTypes.plural": "Event types",
   "eventTypes.create": "Create Event type",
   "eventTypes.update": "Update Event type",
   "eventTypes.create.success": "Event type created successfully",
   "eventTypes.update.success": "Event type updated successfully",
   "eventTypes.form.eventTypesRoot": "Event type parent",
   "eventTypes.emptyWarning": "Please create an event type before continuing",
   "eventTypes.found": "{count, plural, =0 {No event type found} one {1 event type found} other { {count} event type found}}",
   "eventLocation": "{count, plural, one {Event location} other {Event locations}}",
   "eventLocation.plural": "Event locations",
   "eventLocation.create": "Create Event location",
   "eventLocation.update": "Update Event location",
   "eventLocation.create.success": "Event location created successfully",
   "eventLocation.update.success": "Event location updated successfully",
   "eventLocation.emptyWarning": "Please create an event location before continuing",
   "eventLocation.found": "{count, plural, =0 {No event location found} one {1 event location found} other { {count} event locations found}}",
   "executiveOffice": "Executive office",
   "officePositions": "{count, plural, one {Office position} other {Office positions}}",
   "officePositions.plural": "Office positions",
   "officePositions.create": "Create Office position",
   "officePositions.update": "Update Office position",
   "officePositions.create.success": "Office position created successfully",
   "officePositions.update.success": "Office position updated successfully",
   "officePositions.found": "{count, plural, =0 {No office position found} one {1 office position found} other { {count} office positions found}}",
   "cashCategories": "{count, plural, one {Cash category} other {Cash categories}}",
   "cashCategories.plural": "Cash categories",
   "cashCategories.create": "Create Cash category",
   "cashCategories.update": "Update Cash category",
   "cashCategories.create.success": "Cash category created successfully",
   "cashCategories.update.success": "Cash category updated successfully",
   "cashCategories.emptyWarning": "Please create a cash category before continuing",
   "cashCategories.found": "{count, plural, =0 {No cash category found} one {1 cash category found} other { {count} cash categories found}}",
   "cashCategories.form.cashCategoriesRoot": "Cash categories parent",
   "cashCategories.form.direction": "Cash category type",
   "cashCategories.form.cash.in": "Cash in",
   "cashCategories.form.cash.out": "Cash out",
   "cashCategories.form.cash.mix": "Cash in / Cash out",

   "cashFlow": "{count, plural, one {Cash flow} other {Cash flow}}",
   "cashFlow.plural": "Cash flow",
   "cashFlow.create": "Create Cash flow",
   "cashFlow.update": "Update Cash flow",
   "cashFlow.create.success": "Cash flow created successfully",
   "cashFlow.update.success": "Cash flow updated successfully",
   "cashFlow.emptyWarning": "Please create a cash flow before continuing",
   "cashFlow.found": "{count, plural, =0 {No cash flow found} one {1 cash flow found} other { {count} cash flow found}}",
   "cashFlow.form.root": "Cash flow parent",
   "cash.type": "Cash type",

   "accounts": "{count, plural, one {Account} other {Accounts}}",
   "accounts.plural": "Accounts",
   "accounts.found": "{count, plural, =0 {No account found} one {1 account found} other { {count} account found}}",
   "accounts.active": "Active Account",
   "accounts.deleted": "Deleted Account",
   "accounts.pending.deleted": "Account Pending Deletion",
   "accounts.pending.activation": "Account Pending Activation",
   "accounts.delete.done": "Account deleted",
   "accounts.activation.done": "Account activated",
   "accounts.restore.text": "Do you really want to restore this account?",
   "accounts.restore.success": "Account restored successfully",
   "accounts.delete.response.accept.text": "Do you really want to delete?",
   "accounts.activation.response.accept.text": "Do you really want to accept the activation?",
   "accounts.delete.response.denied.text": "Do you really want to refuse the deletion?",
   "accounts.activation.response.denied.text": "Do you really want to refuse the activation?",
   "accounts.delete.response.accept.success": "Your response has been successfully sent",
   "accounts.activation.response.accept.success": "Your response has been successfully sent",
   "accounts.delete.response.denied.success": "Your response has been successfully sent",
   "accounts.activation.response.denied.success": "Your response has been successfully sent",
   "accounts.delete.ask.message": "The owner wants to delete this account",
   "accounts.activation.ask.message": "The owner wants to restore this account",
   "accounts.delete.pending": "Your account is being deleted",
   "accounts.activation.pending": "Your account is being activated",
   "accounts.delete.message": "Do you really want to delete this account ?",
   "accounts.activation.message": "Do you really want to activate this account back ?",
   "accounts.delete.success": "Your request has been successfully sent",
   "accounts.activation.success": "Your request has been successfully sent",
   "accounts.credit.title": "Account credit",
   "accounts.credit.success": "Account credited successfully",
   "accounts.credit.form.error": "Please enter at least {value} {currency}",
   "accounts.types": "{count, plural, one {Account type} other {Account types}}",
   "accounts.types.plural": "Account types",
   "accounts.types.create": "Create Account type",
   "accounts.types.update": "Update Account type",
   "accounts.types.create.success": "Account type created successfully",
   "accounts.types.update.success": "Account type updated successfully",
   "accounts.types.form.eventTypesRoot": "Account type parent",
   "accounts.types.emptyWarning": "Please create an account type before continuing",
   "accounts.types.found": "{count, plural, =0 {No account type found} one {1 account type found} other { {count} account type found}}",

   "accounts.management": "Accounts Management",

   "transactions": "{count, plural, one {Transaction} other {Transactions}}",
   "transactions.plural": "Transactions",
   "transactions.found": "{count, plural, =0 {No transactions found} one {1 transaction found} other { {count} transactions found}}",

   "families.plural": "Families",
   "families.search": "Search families",
   "families.all": "All families",
   "families.all.accounts": "Families accounts",
   "families.recent": "Recent families",
   "families.create": "Create Family",
   "families.update": "Update Family",
   "families.create.success": "Family created successfully",
   "families.create.fromFile.success": "Famlies has been created successfully",
   "families.update.success": "Family updated successfully",
   "families.found": "{count, plural, =0 {No families found} one {1 families found} other { {count} families found}}",
   "families.members": "Families members",
   "families.address.notFound": "Address not yet defined !",
   "families.balance": "Total of families balance",
   "families.members.plural": "Families members",
   "families.members.nonUser.add.message": "Add new member",
   "families.members.nonUser.add.success": "Member added successfully to the family",
   "families.members.nonUser.update.warning": "You have to complete the profile first !",
   "families.members.nonUser.update.success": "Member updated successfully to the family",
   "families.members.profile.completion": "Profile completion",
   "families.events.plural": "Family's Events",
   "families.events.pay.success": "Operation completed successfully",
   "families.events.pay.title": "Families event pay",
   "families.members.insured.total": "Total members insured",
   "families.events.pay.btn": "Collect {value} {currency}",
   "families.management": "{count, plural, one {Family management} other {Families management}}",
   "families.management.plural": "Families management",

   "residence": "{count, plural, one {Residence status} other {Residences status}}",

   "relation": "{count, plural, one {Relationship status} other {Relationships status}}",

   "insurances.plural": "Insurances",
   "insurances.action": "Insurance action",
   "insurances.insured.question": "Insured ?",
   "insurances.request": "{count, plural, one {Insurance request} other {Insurance requests}}",
   "insurances.request.found": "{count, plural, =0 {No insurance request} one {1 insurance request found} other { {count} insurance requests found}}",
   "insurances.request.response.accept.text": "Do you really want to accept the request?",
   "insurances.request.response.denied.text": "Do you really want to cancel the request?",
   "insurances.request.response.accept.success": "Your response has been successfully sent",
   "insurances.request.response.denied.success": "Your response has been successfully sent",

   "tasks": "{count, plural, one {Task} other {Tasks}}",
   "tasks.plural": "Tasks",
   "tasks.search": "Search tasks",
   "tasks.all": "All tasks",
   "tasks.activated": "Activated tasks",
   "tasks.unactivated": "Unactivated tasks",
   "tasks.recent": "Recent tasks",
   "tasks.create": "Create Task",
   "tasks.update": "Update Task",
   "tasks.create.success": "Task created successfully",
   "tasks.update.success": "Task updated successfully",
   "tasks.activate.message": "Do you really want to activate this task ?",
   "tasks.activated.success": "Task activated successfully",
   "tasks.found": "{count, plural, =0 {No tasks found} one {1 tasks found} other { {count} tasks found}}",

   "documents.plural": "Documents",
   "documents.types": "{count, plural, one {Document type} other {Document types}}",
   "documents.types.plural": "Document types",
   "documents.types.create": "Create Document type",
   "documents.types.update": "Update Document type",
   "documents.types.create.success": "Document type created successfully",
   "documents.types.update.success": "Document type updated successfully",
   "documents.types.form.eventTypesRoot": "Document type parent",
   "documents.types.emptyWarning": "Please create an document type before continuing",
   "documents.types.found": "{count, plural, =0 {No document type found} one {1 document type found} other { {count} document types found}}",

   "users": "{count, plural, one {User type} other {Users}}",

   "notifications": "{count, plural, one {Notification} other {Notifications}}",
   "notifications.plural": "Notifications",
   "notifications.search": "Search notifications",
   "notifications.empty": "No notifications for the moment",
   "notifications.mark.read.success": "Notification updated successfully",

   "reports.overview": "Reports",
   "reports.overview.plural": "Reports",

   "request.error.401": "Connection expired. Please log in again",
   "request.error.403": "You do not have the right to perform this action",
   "request.error.404": "Not found. Please try again later",
   "request.error.500": "An error occurred. Please try again later",
   "request.error.unknown": "Unable to perform any requests. Please check your internet connection and try again. If the problem persists please contact support.",
   "request.error.auth.failed": "Bad login and/or password",
   "request.error.auth.login.failed": "Bad login and/or password",
   "request.error.auth.blockedUser": "Sorry, you are not allowed to log into this association",
   "request.error.auth.notAllowed": "Sorry, you are not allowed to log into this association",
   "request.error.plan.nameAssigned": "The plan already exists",
   "request.error.plan.permissionParsingError": "Error while parsing permission, some fields are missing",
   "request.error.plan.featureParsingError": "Error while parsing feature, some fields are missing",
   "request.error.plan.notFound": "The plan was not found",
   "request.error.plan.maxActivatedPlanReached": "You have already reached the limit of three plans activated",
   "request.error.discount.notFound": "The discount was not found",
   "request.error.user.loginNotUnique": "This login is already assigned",
   "request.error.user.notConnected": "User not connected",
   "request.error.sampleCommunity.subDomainNotUnique": "This sub domain is already taken. Please choose another one",

   "languages.english": "English",
   "languages.french": "French",
};

// a.replace(/branch/g, 'network').replace(/Branch/g, 'Network');
